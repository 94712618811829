const Paginate = class {
   
    constructor() {
        this.start = 1;
        this.end = 20;
        this.total = 100;
        this.limit = 20;
    }
    
    set(start = 1, end = 20, total = 100, perPage = 20) {
        this.start = start;
        this.end = end;
        this.total = total;
        this.limit = perPage;
    }
    
    get() {
        return {
            start: this.start,
            end: this.end,
            total: this.total,
            limit: this.limit,
        }
    }
    
    onStart(value) {
        this.start -= value;
        this.end -= value;
    }

    onEnd(value) {
        this.start += value;
        this.end += value;
    }
}

export const paginate = new Paginate()