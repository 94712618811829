<template>
  <div>
    <div
      class="flex justify-center rounded border border-solid border-gray-400 py-2 px-4 pointer"
      :class="[activeBrowse, classes]"
      @click="onFileBrowse"
    >
      <span class="action flex">
        <md-icon :class="[classes]" class="text-base mr-1">videocam</md-icon>
        {{ placeholder }}
      </span>
    </div>

    <input ref="file" type="file" accept="video/*" class="hidden" @change="handleFileUpload( $event )"/>
  </div>
</template>

<script>
import { putObject} from "@/store/upload";
const AWS = require('aws-sdk');
const spacesBaseUrl = new AWS.Endpoint(process.env.VUE_APP_DIGITALOCEAN_SPACES_ENDPOINT);
const urlMarge = spacesBaseUrl.protocol+'//'+process.env.VUE_APP_DIGITALOCEAN_SPACES_BUCKET+'.'+spacesBaseUrl.host
const baseUrl =urlMarge+'/'+process.env.VUE_APP_DIGITALOCEAN_SPACES_FOLDER+'/'

export default {
     components: {
    },
  data() {
    return {
      record: true,
      fileName: "",
      path:'quiz'
      }
    },
    props: {
        iconClass: {
            type: String,
            default: () => ''
        },
        switcher: {
            type: Boolean,
            default: () => true
        },
        label: {
          type: String,
          default: () => 'Start Recording'
        },
        type: {
          type: String,
          default: () => '',
        },
        placeholder: {
          type: String,
          default: 'Upload Video'
        },
        icon: {
          type: String,
          default: 'videocam'
        },
        classes: {
          type: String,
          default: ''
        }
    },
    computed: {
      activeBrowse() {
        return !this.record ? 'bg-victoria text-white' : ''
      },
    },

    methods: {

      handleFileUpload( event ){
            this.file = event.target.files[0];
            console.log(this.file)
            console.log("CustomData",this.file)
            this.file_url = URL.createObjectURL(this.file)
            //path assign here
            const paths = this.path +'/video/'+ this.file.name 
            this.file_path = paths.toLowerCase();
            this.filepath = this.path+'/video'
            putObject(this.file, this.filepath, "public-read")
            this.$emit('input',baseUrl+paths);
      },

      onFileBrowse() {
        this.record = false;
        this.$refs.file.click();
      },
    }
};
</script>

<style>
#video-preview{
    height: 220px;
}
</style>
