<template>
    <div>
      <div class="flex justify-between align-center mb-6">
        <h2 class="text-uppercase font-bold">Create New Online Class</h2>
      </div>
      <div class="test-layout create-class">
        <div>
          <ul class="md-sidebar_active_line">
            <li v-for="(item, index) in steps" :key="index">
              <router-link 
                @click.native="nextPage('current')" 
                class="inline-flex py-2 pointer-events-none" :to="{name: item.routeName, query: {type: item.query}, params: {id: item.id}}"
              >
                <svg class="h-5 w-5 md-acive_icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24">
                  <path :fill=" item.complete || selectedRouteName === item.routeName ? '#661dd0' : 'Current'" d="M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M17.7,9.7l-7,7C10.5,16.9,10.3,17,10,17s-0.5-0.1-0.7-0.3l-3-3c-0.4-0.4-0.4-1,0-1.4s1-0.4,1.4,0l2.3,2.3l6.3-6.3c0.4-0.4,1-0.4,1.4,0S18.1,9.3,17.7,9.7z"  />
                </svg>
                <span class="md-acive_icon text-capitalize ml-2 text-base">{{ item.label }}</span>
              </router-link>
              
              <ul class="ml-8 mr-8 md-sidebar_active_line" v-if="$route.name == 'onlineClassCreation.chapterDetails' && item.routeName == 'onlineClassCreation.chapterDetails'">
                <InputItemCollapse />
              </ul>
            </li>
          </ul>
        </div>
        <div>
          <router-view></router-view>
        </div>
      </div>
    </div>
  </template>
  <script>
  import { findIndex } from 'lodash';
  import data from '@/data/online-class/data'
  import { mapGetters, mapActions } from 'vuex';
  import InputItemCollapse from '@/components/molecule/online-class/create/form/InputItemCollapse.vue';

  export default {
    components: {
      InputItemCollapse,
    },
    data() {
      return {
        showSub_id: 0,
        currentPage: '',
        selectedRouteName: '',
      }
    },
    computed: {
      ...mapGetters({
        getSectionWiseQuiz: "question/getSectionWiseQuiz"
      }),
      type() {
        return this.$route.query.type
      },
      steps() {
        return data.steps.map(item => {
          item.query = this.type;
          return item;
        })
      },
    },
    methods: {
      ...mapActions({
        actSectionWiseQuiz: "question/actSectionWiseQuiz",
      }),
      nextPage(page = 'next') {
       let index = findIndex(this.steps, ['routeName', this.$route.name]);
        if(index !== this.steps.length - 1 && page != 'current') {
          let routeName = this.steps[index + 1].routeName;
          this.$router.push({
            name: routeName
          })
        }
      },
    },
    mounted() {
      this.nextPage('current');
    },
    watch: {
      '$route': {
        handler() {
          this.selectedRouteName = this.$route.name;
        },
        immediate: true,
        deep: true
      }
    }
  }
  </script>
  <style lang="scss">
  .test-layout {
    display: grid;
    grid-template-columns: minmax(150px, 25%) 1fr;
    a {
      color: rgb(155, 155, 155) !important;
      &.router-link-active {
        font-weight: 600;
      }
    }
  
  }
  .subnav_color span{
    color: #7432b8 !important;
  }
  .subSvg_color{
    fill: #7432b8 !important;
  }
  </style>
  