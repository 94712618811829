<template>
    <div class="border rounded-lg border-solid border-gray-400  py-2 px-6 relative flex text-center justify-center">
        <div class="md-layout flex align-center mt-1 justify-left">
            <span class="mr-4">
                <img v-if="item.type === 'pdf'" class="rounded w-32 h-10" src="https://media.istockphoto.com/id/949118068/photo/books.jpg?s=612x612&w=0&k=20&c=1vbRHaA_aOl9tLIy6P2UANqQ27KQ_gSF-BH0sUjQ730="/>
                <md-icon v-if="item.type === 'docs'" class="text-primary h-10 text-4xl">library_books</md-icon>
            </span>    
            <h2 class="text-justify text-xl">{{ item.title }}</h2>
            <span class="ml-4 pointer">
                <md-icon v-if="item.type === 'pdf'" class="text-blue bg-trans-blue text-lg rounded-full p-4">visibility</md-icon>
                <md-icon v-if="item.type === 'docs'" class="text-blue bg-trans-blue text-lg rounded-full p-4">arrow_forward</md-icon>
            </span>
        </div>
    </div>
</template>

<script>
export default {
  props: {
    item: {
        type: Object
    },
  },
}
</script>