<template>
      <div class="flex justify-center ml-4">
        <tr>
          <td v-if="type != 'quiz'">{{ this.item.short_title }}</td>
          <td v-if="isShowPreparationTime">
            <vue-timepicker
              close-on-complete
              v-model="preparationTime"
              format="mm:ss"
            ></vue-timepicker>
          </td>
          <td>
            <vue-timepicker
              close-on-complete
              v-model="answerTime"
              format="mm:ss"
            ></vue-timepicker>
          </td>
          <td v-if="type != 'quiz'"> 
            <md-switch  v-model="isBeep"></md-switch>
          </td>
        </tr>
      </div> 
</template>

<script>
import VueTimepicker from 'vue2-timepicker';
import { secondToTime, timeToSecond } from '@/utils';
import { mapGetters } from 'vuex';
export default {
  components: {
    VueTimepicker
  },
  props: {
      label: {
          type: String
      },
      item: {
          type: Object
      },
      type:{
        type: String
      },
      isShowPreparationTime: {
        type: Boolean,
        default: true,
      }
  },
  computed: {
    preparationTime: {
      get() {
        return secondToTime(this.item.preparation_time)
      },
      set(value) {
        this.item.preparation_time = timeToSecond(value)
      }
    },
    
    answerTime: {
      get() {
        return secondToTime(this.item.answer_time)
      },
      set(value) {
        this.item.answer_time = timeToSecond(value)
      }
    },
    ...mapGetters({
      getQuestionTypes: "question/getQuestionTypes"
    }),
    items() {
      return this.getQuestionTypes.map(item => {
        item.label = item.name;
        return item;
      });
    }
  },
  mounted(){
    this.itemsType = localStorage.getItem("HandleItems");
    if(this.item.id == this.itemsType){
      this.itemList = this.item.id;
    }
  },
  data() {
    return {
      isBeep: this.item.is_beep != 0
    }
  },
  watch: {
    isBeep(value) {
      this.item.is_beep = value;
    }
    
  }
};
</script>

<style>

.test-time {
  border-collapse: collapse;

}
.test-time thead th,
.test-time tbody td {
  padding: 10px;
}
</style>
