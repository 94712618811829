<template>
    <div class="outline-gray-400 rounded-md px-6 pb-6 flex flex-col align-center h-full relative">
        <!-- <Tab 
            :items="items" 
            :classes="'border-none'" 
			:itemClass="'text-capitalize flex align-center mb-4'"
            :activeClass="'tab-item-active'"
            class="border-b border-b-solid border-gray-300 text-center w-100p"
        >
        </Tab> -->
        <Tab 
			v-if="items.length"
            :items="items" 
			wrapperClass="c_simple_tab_two"
			@activeTab="onSetTab"
			titleKey="title"
        >
        </Tab>
		
        <section class="my-6 w-100p">
            <div class="text-xl font-semibold">{{ selectedModule?.title }}</div>
            <p class="mt-3 text-sm text-capitalize line-height-27" v-html="selectedModule?.description"></p>
        </section>

        <section class="md-layout w-100p rule-status">
            <div class="md-layout-item  bg-lite-algal border border-algal-lite border-solid  rounded-lg mt-2 py-2 px-3 text-xs rounded text-start"> 
                <span class="text-algal text-base font-bold">Rule : </span>
                <span class="text-base">{{ rule(selectedModule?.rules) }}</span>
            </div>
        </section>

        <section class="chapter-section w-100p">
            <ChapterCard 
                class="w-100p" 
                v-for="(chp, index) in selectedModule?.chapters" 
                :key="index" 
                :item="chp"
            />
        </section>

        <section class="w-100p">
            <div class="flex align-center justify-end mt-6">
                <md-button 
                    class="px-5 py-2 h-12 text-base text-transform-none font-semibold text-gray-500 rounded-lg"
                    @click="goBack"
                >
                    Back
                </md-button>
                <Button 
                    label="Save & Next" 
                    type="submit" 
                    class="mr-0 px-5 py-2 h-12 bg-algal text-base font-semibold text-white rounded-lg"
                    @click.native="onSubmit()"
                >
                    Save & Next
                </Button>
            </div>
        </section>
    </div>
</template>

<script>
import { Button } from "@/components";
import Tab from '@/components/atom/SimpleTab.vue';
import ChapterCard from '@/components/molecule/create-review/ChapterCard.vue';
import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
	name: 'IndexView',

	components: {
		Tab,
        ChapterCard,
        Button,
	},

	data() {
		return {
			component: 'ModuleForm',
			content: {},
			items: [],
            selectedTab: null,  
            selectedTabIndex: -1,
			selectedModule: null,
		}
	},
    computed: {
		...mapGetters({
            getModules: "onlineClass/getModules",
            getModule   : "onlineClass/getModule",
			getClassInfo: "onlineClass/getClassInfo",
		}),
    },
    methods: {
        ...mapMutations({
			setModule: "onlineClass/setModule",
			setModules: "onlineClass/setModules",
		}),
        ...mapActions({
            initialClassInfo: "onlineClass/initialClassInfo"
        }),
        rule(rules) {
            if(rules.rule === 'no_rule') {
                return 'No Rules'
            } else if(rules.rule === 'publish_after') {
                return `Publish after ${rules.rule_value} hours of the opening of previous chapter`
            }
        },
        goBack() {
            this.$router.push({
                name: 'onlineClassCreation.rules',
            });
        },
        onSubmit() {
			this.$router.push({
				name: 'onlineClassCreation.preview',
			})
		},
        onSetTab(tab) {
			let findIndexBySelectedTab = this.getModules.findIndex((val) => val.title === tab);

			if(this.selectedModule) {
				let index = this.getModules.findIndex((val) => val.title === this.selectedModule.title);
				if(index > -1 && this.selectedModule) {
					let cloneModules = this.getModules;
					cloneModules[index] = this.selectedModule;
					this.setModules(cloneModules);
				}
			}
			
			if(findIndexBySelectedTab > -1) {
				this.setModule(this.getModules[findIndexBySelectedTab]);
			}

            this.selectedTab = tab;
        }
    },
    watch: {
		'getModules': {
			deep: true,
            immediate: true,
			handler: function(value) {
				if(value) {
					this.items = value;
	
					if(this.selectedModule) {
						let index = this.getModules.findIndex((val) => val.title === this.selectedModule.title);
						if(index > -1) {
							this.selectedTabIndex = index;
							this.selectedModule = JSON.parse(JSON.stringify(this.getModules[index]))
						}
					}
				}
			}
		},
		getModule(value) {
            if(value) {
				let index = this.getModules.findIndex((val) => val.title === value.title);
				if(index > -1) {
					this.selectedTabIndex = index;
					this.selectedModule = JSON.parse(JSON.stringify(this.getModules[index]))
				}
			}
		},
	},
	created() {
		this.initialClassInfo();
		this.items = JSON.parse(JSON.stringify(this.getModules));

        this.setModule(this.items[0]);
		let index = this.getModules.findIndex((val) => val.title === this.getModule.title);
		
		if(index > -1) {
			this.selectedTabIndex = index;
			this.selectedModule = JSON.parse(JSON.stringify(this.getModules[index]));
		}
	}
}
</script>

<style lang="scss" scoped>
.tab-item-active {
    font-weight: bold;
    color: #0000 !important;
    border-bottom: 2px solid #661dd0 !important;
    margin-bottom: -2px;
}
</style>