<template>
  <div v-if="preview">
    <div  class="outline-gray-400 rounded-md p-6 flex flex-col justify-between h-full">
      <div class="text-uppercase  bg-link-water p-6 rounded flex justify-between align-center">
      <div class="flex align-center">
        <h2 class="text-2xl font-extrabold text-primary">{{ question.question_type.section}}</h2>
        <p class="text-gray-600 text-lg font-bold"><span class="text-primary pr-2 pl-2 ml-2 md-pte-back_prev">{{ questionType.title }}</span> </p>
      </div>
    </div>
      <!-- <ParticipationSubHeader :question="question" /> -->
      <PreviewView :question="question" />
      <!--- Dynamic component -->
        <!-- <components :content="question" v-model="answer" ref="child" :is="paritciaptionComponents"></components> -->
      <!--- Dynamic component -->
        <div class="flex justify-center mt-16">
          <md-button 
            @click="onPrevious(currentIndex)" 
            :disabled="this.currentIndex === 0" 
            class="prev rounded text-white my-0 ml-0"
            :class="currentIndex === 0 ? 'bg-periwinkle-gray': 'bg-victoria'"
          >
          <md-icon class="text-white m-0">keyboard_arrow_left</md-icon> 
            Prev
          </md-button>

          <md-button 
            @click="onNext(currentIndex)" 
            :disabled="this.currentIndex >= this.questionIds.length" 
            class="next rounded text-white my-0 mr-0"
            :class="currentIndex >= questionIds.length ? 'bg-periwinkle-gray': 'bg-victoria'"
          >
            Next
            <md-icon class="text-white m-0">keyboard_arrow_right</md-icon>
          </md-button>
        </div>
      </div>
      <div class="flex justify-between align-center mt-6">
      <div>
        <span class="outline-gray-400 rounded px-1 mr-2">{{ questionIds.length }} Question added</span>
        <!-- <span class="outline-gray-400 rounded px-1 ml-2">114:00</span> -->
      </div>
      <div>
        <md-button @click="onBack" class="text-uppercase rounded">Prvious</md-button>
        <md-button class="text-uppercase">Save as draft</md-button>
          <Button
            :loading="loading"
            class="bg-victoria rounded text-white mr-0"
            label="Publish"
            type="button"
            @click="publishTest"
            >
          </Button>
      </div>
    </div>
  </div>
  <div class="outline-gray-400 rounded-md p-6 h-full w-full center" v-else>
    <SuccessComponent message="Test has been created" />
  </div>
</template>

<script>
import { Button } from '@/components'
//import ParticipationHeader from "@/components/molecule/question/ParticipationHeader";
//import ParticipationSubHeader from "@/components/molecule/question/ParticipationSubHeader";
//import questionParicipationComponents from "@/components/molecule/question-participation";
import PreviewView from '@/views/teacher/test-creation/create/PreviewView.vue'
import { mapActions, mapMutations } from 'vuex';
import { slugToComponent } from '@/utils'

import SuccessComponent from '@/components/molecule/SuccessComponent'
export default {
  components: {
    Button,
    SuccessComponent,
   // ParticipationHeader,
    PreviewView,
    //ParticipationSubHeader,
    // ...questionParicipationComponents
  },
  data() {
    return {
      preview: true,
      loading: false,
      paritciaptionComponents: '',
      answer: "",
      currentIndex: 0,
      question: {},
      meta: {},
      content: {},
      questionType: {},
      questionIds: [],
    }
  },
  computed: {
    examEnrollId() {
      return this.$route.query.enrollId
    },
    test() {
      return JSON.parse(localStorage.getItem('test'));
    },
    currentPage() {
      return this.meta.current_page
    },
    total() {
      return this.meta.total
    },
    type() {
      return this.$route.query.type
    },
  },
  methods: {
    ...mapMutations({
      setShowSnackbar: "setShowSnackbar"
    }),
    ...mapActions({
      actTestQuestionsIds: "testCreation/actTestQuestionsIds",
      actQuestionDetails: "question/actQuestionDetails",
      actTestStatusUpdate: "testCreation/actTestStatusUpdate",
    }),
    onNext(index) {
      if(this.currentIndex > this.questionIds.length) {
        return;
      } else {
        if(this.questionType.length-1!=this.currentIndex){
          this.indexs = index;
        }
        this.actQuestionDetails(this.questionIds[this.indexs].id).then(res => {
          this.question = res;
          this.questionType = res.question_type;
          this.paritciaptionComponents = slugToComponent(this.questionType.slug);
          this.currentIndex++;
        })
      }
    },
    onPrevious(index = 0) {
      if(this.currentIndex < 0) {
        return;
      }
      if(this.currentIndex!=0){
        this.indexs = index - 1;
      }
      
      this.actQuestionDetails(this.questionIds[this.indexs].id).then(res => {
        if(this.currentIndex == 0){
          this.currentIndex; 
          document.getElementsByClassName('prev').disabled = true;
        } else {
          this.question = res;
          this.questionType = res.question_type;
          this.paritciaptionComponents = slugToComponent(this.questionType.slug);
          this.currentIndex--; 
        }
      })
    },
    onBack() {
      this.$router.push({
        name: 'testCreation.review',
        query: {type: this.type}
      });
    },
    publishTest() {
      this.loading = true;
      this.actTestStatusUpdate({
        testId: this.test.id,
        payload: {
          active:true
        }
      }).then(() => {
        this.setShowSnackbar('Test created successfylly');
        this.preview = this.loading = false;

        localStorage.removeItem('selectedQuestions');
        localStorage.removeItem('selectedSectionsUpdated');
        localStorage.removeItem('question_type_id');
        localStorage.removeItem('createTypeResponse');
        localStorage.removeItem('testType');
        localStorage.removeItem('test');
        localStorage.removeItem('selectedSections');
        localStorage.removeItem('testQuestionCount');

      });
    }
  },
  created() {
    this.actTestQuestionsIds(this.test.id).then(response => {
      this.questionIds = response.question_ids;
      this.actQuestionDetails(response.question_ids[0].id).then(res => {
        this.question = res;
        this.questionType = res.question_type;
        this.paritciaptionComponents = slugToComponent(this.questionType.slug);
      })
    });
  }
};
</script>