<template>

  <div class="outline-gray-400 rounded-md p-6 flex flex-col justify-between h-full">
    <h2 class="text-uppercase mb-12">Number of question preset</h2>
    <div class="md-layout md-gutter block">
      <div v-for="(item, index) in section" :key="index" class="flex align-baseline mb-6 mt-4 md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-50">
          <div class="mr-12">
            <h2 class="text-victoria text-base text-uppercase mb-6" v-show="index === 0">Question Type</h2>
            <span class="select">
              <select>
                <option>{{item.quiz_type_title}}</option>
              </select>
            </span>
          </div>
          <div class="ml-4">
           <h2 class="text-victoria text-base text-uppercase mb-6" v-show="index === 0">No of Questions</h2>
           <QuestionNumberInput
            class="number_q"
            :item="item"
          />
          </div>
      </div>
    </div>
    <div class="md-layout md-gutter block mb-6">
        <div class="md-layout-item">
          <p class="text-uppercase text-base font-semibold">Negative Marking</p>
        </div>
        <div class="md-layout-item">  
            <md-radio v-model="negative_marking" :value="0">No</md-radio>
            <md-radio v-model="negative_marking" :value="1">Yes</md-radio>
        </div>
    </div>
    <div class="flex justify-end mt-4">
      <md-button class="text-uppercase rounded">Clear all</md-button>
      <md-button @click="onBack" class="text-uppercase rounded">Previous</md-button>
      <Button
        :loading="loading"
        class="bg-victoria rounded text-white mr-0"
        label="Next"
        type="button"
        @click="submit"
        >
      </Button>
    </div>
  </div>
</template>


<script>
import QuestionNumberInput  from '@/components/molecule/test-creation/QuestionNumberInput';
import  data  from '@/data/test-creation/data';
import { Button } from '@/components';
import { flatten } from 'lodash';
export default {
  components: {
    Button,
    QuestionNumberInput
  },
  data() {
    return {
      loading: false,
      items: data.SectionTypes,
      condition:false,
      negative_marking: 0,
    }
  },
  computed: {
    section() {
      return JSON.parse(localStorage.getItem('selectedSectionsUpdated'));
    },
    type() {
      return this.$route.query.type
    },
  },
  methods: {

    submit() {
      let data = [];
        let test = JSON.parse(localStorage.getItem('test'));

        Object.keys(this.section).map(item => {
          data.push(this.section[item]);
        });

        test.times = flatten(data).map(item => {
          return {
            answer_time: item.answer_time,
            id: item.id,
            is_beep: item.is_beep,
            // preparation_time: item.preparation_time,
            // quantity: Number(item.quantity),
            quantity: item.quantity ? Number(item.quantity) : item.quantity,
            quiz_set_id: item.quiz_set_id,
            quiz_type_id: item.quiz_type_id,
            quiz_type_title: item.quiz_type_title,
            number_of_question: item.number_of_question,
          }
        });
        test.negative_marking = this.negative_marking;

        // let excludes = ['id', 'created_by', 't_id', 'created_at','quiz_times'];
        // excludes.forEach(item => delete test[item]);

        localStorage.setItem('test', JSON.stringify(test));
        localStorage.setItem('quizQuestionCount', JSON.stringify(test));
        localStorage.setItem('selectedSectionsUpdated', JSON.stringify(test.times));

        this.$router.push({
          name: 'quizCreation.createType',
          query: {type: this.type}
        });
    },

    onBack() {
      this.$router.push({
        name: this.type === 'quiz-test' ? 'quizCreation.timeSet' : 'testCreation.timeSet',
        query: {type: this.type}
      });
    },

  },
  created()  {
    const data = JSON.parse(localStorage.getItem('quizQuestionCount'));
    this.negative_marking = data.negative_marking;

    if(data && data.times) {
      localStorage.setItem('selectedSectionsUpdated', JSON.stringify(data.times));
    }
  }
}
</script>
<style scoped>
select {
   -webkit-appearance:none;
   -moz-appearance:none;
   -ms-appearance:none;
   appearance:none;
   outline:0;
   box-shadow:none;
   border:0!important;
   background-image: none;
   flex: 1;
   padding: 0 .5em;
   color:#000;
   cursor:pointer;
   font-size: 1em;
   font-family: 'Open Sans', sans-serif;
}
.select::after {
   content: '\25BC';
   position: absolute;
   top: 0;
   right: 0;
   padding: 0 1em;
   cursor:pointer;
   pointer-events:none;
   transition:.25s all ease;
}
.select {
   position: relative;
   border: 1px solid #8d8787;
   display: flex;
   width: 200px;
   height: 3em;
   line-height: 3;
   background: #5c6664;
   overflow: hidden;
   border-radius: .25em;
}

</style>