<template>
    <div class="md-layout md-gutter m-5 pb-3 mt-6 mb-0">
        <InputFieldComponent
            v-model="form.title"
            label="Module title"
            label-class="text-base mb-4 font-semibold text-capitalize"
            placeholder="Write Here..."
            :classes="['border', 'border-solid', 'rounded-md', 'border-gray-400']"
            class="w-100p"
            :inputClass="'p-2 font-semibold'"
            :message="!$v.form.title.required && $v.form.title.$dirty ? 'Field is required' : null"
        />
        <div class="w-full flex justify-center align-center mt-8 mb-5 mr-2">
            <Button
                class="size-lg text-lg text-capitalize h-12 rounded-lg px-3 text-black mr-0 border border-solid border-gray-500 mr-4"
                label="cancel"
                type="submit"
                @click="dialog(false)"
            >
            </Button>
            <Button
                class="px-3 bg-algal rounded-lg text-white mr-0 size-lg text-lg text-capitalize h-12"
                label="Save"
                type="submit"
                @click="onSubmit()"
            >
            </Button>
        </div>
    </div>
</template>

<script>
import { InputFieldComponent, Button } from "@/components";
import { mapMutations, mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";

export default {
    name: 'ModuleForm',

    components: {
        InputFieldComponent,
        Button
    },

    data() {
        return {
            form: {
                title: '',
                description: '',
                chapters: [
                    {
                        title: ''
                    },
                    {
                        title: ''
                    }
                ],
            }
        }
    },
    computed: {
		...mapGetters({
			getModules: "onlineClass/getModules",
		}),
    },
    methods: {
        ...mapMutations({
            dialog: "setShowDialog",
            newAddModule: "onlineClass/newAddModule",
        }),

        onSubmit(){
            this.$v.$touch();
			if(this.$v.$invalid) return;
            
            this.newAddModule(this.form);
            this.dialog(false);
        }
    },
    validations: {
		form: {
			title: {required},
		},
	},
}
</script>

<style lang="scss" scoped>

</style>