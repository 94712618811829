<template>
  <div v-if="preview" class="outline-gray-400">
      <components ref="child" :is="convertor(question.template_type)" :question="question" />
      <div class="flex justify-between align-center mt-6 mb-6 mr-8 ml-4">
        <div>
          <span class="outline-gray-400 rounded px-1 mr-2">{{ question.quizzes.length }} Question added</span>
        </div>

        <div>
          <md-button class="text-uppercase rounded">Save as draft</md-button>
          <md-button @click="onBack" class="text-uppercase rounded">Previous</md-button>
          <Button
              :loading="loading"
              class="bg-victoria rounded text-white mr-0"
              label="Publish"
              type="button"
              @click="publishTest"
          >
          </Button>
        </div>
      </div>
      <md-button @click="onBack" class="text-uppercase rounded">Previous</md-button>
  </div>
  <div class="outline-gray-400 rounded-md p-6 h-full w-full center" v-else>
    <SuccessComponent message="Test has been created" routeName="quizCreation.list"/>
  </div>
</template>

<script>
import { Button } from '@/components'
import QuizType from "@/views/teacher/test-creation/quiz-test/create/quiz-type";
import { mapActions, mapMutations } from 'vuex';
import SuccessComponent from '@/components/molecule/SuccessComponent'
export default {
  components: {
    Button,
    SuccessComponent,
    ...QuizType
  },
  data() {
    return {
      preview: true,
      loading: false,
      paritciaptionComponents: '',
      answer: "",
      currentIndex: 1,
      question: {},
      meta: {},
      content: {},
      questionType: {},
      questionIds: [],
    }
  },
  computed: {
    examEnrollId() {
      return this.$route.query.enrollId
    },
    test() {
      return JSON.parse(localStorage.getItem('test'));
    },
    currentPage() {
      return this.meta.current_page
    },
    total() {
      return this.meta.total
    },
    type() {
      return this.$route.query.type
    },
  },
  mounted(){
    console.log("dd",this.meta.current_page)
  },
  methods: {
    ...mapMutations({
      setShowSnackbar: "setShowSnackbar"
    }),
    ...mapActions({
      actTestQuestionsIds: "quizCreation/actTestQuestionsIds",
      actQuestionDetails: "question/actQuestionDetails",
      actTestStatusUpdate: "quizCreation/actTestStatusUpdate",
      actTestPublish: "quizCreation/actTestPublish",
    }),
    convertor(title){
      if(title === 'ONE_BY_ONE'){
        return 'OneByOne'; 
      } 
      if(title === 'ALL_IN_ONE'){
        return 'AllInOne';
      }
    },
    onBack() {
      this.$router.push({
        name: this.type === 'quiz-test' ? 'quizCreation.review' : 'testCreation.review',
        query: {type: this.type}
      });
    },
    
    publishTest() {
      this.loading = true;
      this.actTestPublish({
        testId: this.test.id,
        payload: {
          active:true
        }
      }).then(() => {
        this.setShowSnackbar('Test created successfylly');
        this.preview = this.loading = false;

        localStorage.removeItem('test');
        localStorage.removeItem('selectedQuestions');
        localStorage.removeItem('testType');
        localStorage.removeItem('createTypeResponse');
        localStorage.removeItem('selectedSections');
        localStorage.removeItem('testQuestionCount');

      });
    }
  },
  created() {
    this.actTestQuestionsIds(this.test.id).then(response => {
      this.question = response;
    });
  }
};
</script>