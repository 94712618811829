<template>
  <div class="outline-gray-400 rounded-md p-6 flex flex-col justify-between h-full">
    <div>
      <label class="text-uppercase font-bold block text-base">Choose Mock Type</label>
      <RadioButtonComponent    
      label-class="text-lg text-uppercase"
      :items="[
        {id:'RANDOM', label: 'Random'},
        {id:'MANUAL', label: 'Manual'},
      ]"
      v-model.trim="create_type"
    />
    </div>
    <div class="flex justify-end mt-4">
      <md-button @click="onBack" class="text-uppercase rounded">Previous</md-button>

      <Button
        :loading="loading"
        class="bg-victoria rounded text-white mr-0"
        label="Next"
        type="button"
        @click="submit"
        >
      </Button>
    </div>
  </div>
</template>

<script>
import { RadioButtonComponent, Button } from "@/components";
import { mapActions } from 'vuex';
export default {
  name: "QuizCreateTypeView",
  components: {
    Button,
    RadioButtonComponent
  },
  data() {
    return {
      loading: false,
      create_type: ''
    }
  },
  computed: {
    type() {
      return this.$route.query.type
    },
  },
  methods: {
    ...mapActions({
      actTestStepUpdate: "quizCreation/actTestStepUpdate"
    }),
    async submit() {
      localStorage.setItem('testType', this.create_type);

      if(this.create_type == 'RANDOM') {
        this.loading = true;
        let test = JSON.parse(localStorage.getItem('test'));
        let testData = JSON.parse(localStorage.getItem('quizQuestionCount'));
        testData.create_type = this.create_type;

        let response = await this.actTestStepUpdate({
          testId: test.id,
          payload: testData
        });

        localStorage.setItem('createTypeResponse',JSON.stringify(response));

        this.$router.push({
          name: 'quizCreation.chooseQuestion',
          query: {type: this.type}
        });

      } else {
          this.$router.push({
          name: 'quizCreation.chooseQuestion',
          query: {type: this.type}
        });
      }
    
    },

    onBack() {
      this.$router.push({
        name: this.type === 'quiz-test' ? 'quizCreation.numberOfQuestion' : 'testCreation.numberOfQuestion',
        query: {type: this.type}
      });
    }
  },
  watch: {
    create_type(value) {
        let testData = JSON.parse(localStorage.getItem('quizQuestionCount'));
        testData.create_type = value;
        localStorage.setItem('quizQuestionCount',JSON.stringify(testData));
        let test = JSON.parse(localStorage.getItem('test'));
        test.create_type = value;
        localStorage.setItem('test',JSON.stringify(test));
    }
  },
  mounted() {
    let testData = JSON.parse(localStorage.getItem('quizQuestionCount'));
    this.create_type = testData.create_type ? testData.create_type : 'RANDOM';
  }
}
</script>

<style>

</style>