<template>
    <div class="question_list">
            <div class="text-base line-height-27" :class="question.question_type.short_title == 'HIW' ? 'highilight_word':'reading'" v-if="question.title != null">
                <p class="text-uppercase text-victoria" v-if="question.question_type.section != 'SPEAKING'">Question</p>
                <p class="text-uppercase text-victoria" v-if="question.question_type.section == 'SPEAKING'">Title</p>
                <span v-if="question.question_type.short_title != 'FG'" class="ck-content question__t" v-html="question.title">
                </span> 
                <span v-if="question.question_type.short_title == 'FG'" class="ck-content question__t" v-html="this.fillInGap">
                </span> 
            </div>
            <span  v-if="question.question_type.section != 'SPEAKING'">
                <span v-if="question.question_options != ''">
                    <span v-if="question.question_type.short_title != 'FGDD'">
                      <p class="text-uppercase text-victoria" v-if="question.question_type.short_title != 'ROP'" >Answer Options</p>
                    </span>
                </span>
            </span>
            <div class="text-base line-height-27" v-if="question.question_options != null">
                <span v-if="question.question_type.short_title != 'FGDD'">
                    <span class="student_answer_checked" v-if="question.question_type.short_title != 'ROP'">
                        <span class="student_answer_checked">
                            <li v-for="(item, index) in question.question_options" :key="index" class="my-3 flex align-center">
                                <md-checkbox 
                                    v-if="question.question_type.short_title == 'MCQMA' || question.question_type.short_title == 'FGDND' || question.question_type.short_title == 'MCQMA' || question.question_type.short_title == 'FG'"
                                    disabled :disable="disable"  :value="item.is_correct_answer" v-model="disable" :checked="item.is_correct_answer"> 
                                </md-checkbox>
                                <md-checkbox 
                                    v-if="question.question_type.short_title == 'HIW'"
                                    disabled :disable="disable"  :value="item.is_correct_answer" v-model="disable" :checked="item.is_correct_answer"> 
                                </md-checkbox>
                                <md-radio 
                                    v-if="question.question_type.short_title == 'MCQSA' || question.question_type.short_title == 'FGND' || question.question_type.short_title == 'MCQSA' || question.question_type.short_title == 'SMW' || question.question_type.short_title == 'HCS'"
                                    disabled :disable="disable"  :value="item.is_correct_answer" v-model="disable" :checked="item.is_correct_answer"> 
                                </md-radio>
                                <Content :text="item.title" class="w-80 teacher_view_q"/>
                            </li>
                        </span>
                    </span>
                </span>
                <span v-if="question.question_type.short_title == 'FGDD'" class="flex" style="flex-wrap: wrap">
                    <li v-for="(item, index) in question.question_options" :key="index" class="my-3 align-center m-4" style="display:block">
                        <h4 class="text-victoria">Answer {{index+1}}</h4>
                        <span v-for="(item, index) in item.drop_down_options" :key="index" class="flex align-center" style="flex-wrap: inherit">
                            <md-radio 
                                disabled :disable="disable"  :value="item.dd_is_correct_answer" v-model="disable" :checked="item.dd_is_correct_answer"> 
                            </md-radio>
                            <span class="w-80 teacher_view_q">{{item.dd_value}}</span>
                    </span>
                    </li>
                </span>
                <span class="text-base" v-if="question.question_type.short_title == 'ROP'">
                    <div class="copy" style="margin-top:0px">
                        <div class="item">
                            <h4 class="text-uppercase text-victoria pb-3">SOURCE</h4>
                            <div class="md-drop-item pl-1 pr-1">
                                <div v-for="(item,index) in this.reorder_incorrect_pairs" :key="index" class="block">
                                    <div class="draggable-item md-drop-item m-2 p-2 block align-center" v-html="item.value">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="item">
                            <h4 class="text-uppercase text-victoria pb-3">TARGET</h4>
                            <div class="md-drop-item pl-1 pr-1" >
                                <div v-for="(item,index) in this.reorder_correct_pairs" :key="index" class="block">
                                    <div class="draggable-item md-drop-item m-2 p-2 block align-center" v-html="item.value">
                                    <!-- {{item.value}} -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </span>
            </div>  
            <div v-if="question.question_type.short_title == 'SST'">
                <div class="content">
                    <p class="text-uppercase text-victoria">Contents</p>
                    <div class="flex align-center w-full pt-1 pb-1 tag-input_field voc_b_label">
                        <div tabindex="0" class="mb-2 md-chip md-theme-default md-deletable md-clickable" v-for="(content,index) in question.contents.content_array" :key="index">
                        <div class="md-ripple">{{content}}</div> 
                        </div>
                    </div>
                </div>
                <div class="vocabulari">
                    <p class="text-uppercase text-victoria">Vocabulary</p>
                    <div class="flex align-center w-full pt-1 pb-1 tag-input_field voc_b_label">
                        <div tabindex="0" class="mb-2 md-chip md-theme-default md-deletable md-clickable" v-for="(vocabulary,index) in question.vocabularies.vocabulary_array" :key="index">
                           <div class="md-ripple">{{vocabulary}}</div> 
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="question.question_type.section == 'WRITING'">
                <div class="content">
                    <p class="text-uppercase text-victoria">Contents</p>
                    <div class="flex align-center w-full pt-1 pb-1 tag-input_field voc_b_label">
                        <div tabindex="0" class="mb-2 md-chip md-theme-default md-deletable md-clickable" v-for="(content,index) in question.contents.content_array" :key="index">
                        <div class="md-ripple">{{content}}</div> 
                        </div>
                    </div>
                </div>
                <div class="vocabulari">
                    <p class="text-uppercase text-victoria">Vocabulary</p>
                    <div class="flex align-center w-full pt-1 pb-1 tag-input_field voc_b_label">
                        <div tabindex="0" class="mb-2 md-chip md-theme-default md-deletable md-clickable" v-for="(vocabulary,index) in question.vocabularies.vocabulary_array" :key="index">
                           <div class="md-ripple">{{vocabulary}}</div> 
                        </div>
                    </div>
                </div>
            </div>
    </div>
</template>
<script>
import Content from "@/components/atom/Content";
export default {
    components: {
      Content,
    },
    props:{
        question: {
           default: true 
       }
    },
    data() {
        return {
            question_type: [],
            disable: 1,
            reorder_incorrect_pairs: [],
            reorder_correct_pairs: [],
            question_options_hiw: [],
            fillInGap: ''
        }
    },
    watch:{
        question: {
            handler(val){
                this.reorder_incorrect_pairs =  val.reorder_incorrect_pairs.sort((a, b) => a.serial_no - b.serial_no)
                this.reorder_correct_pairs =  val.reorder_correct_pairs.sort((a, b) => a.serial_no - b.serial_no)
            },
        }  
   },
   mounted(){
      this.handler();
      console.log('question',this.question)
   },
    methods: {
        handler(){
                this.reorder_incorrect_pairs =  this.question.reorder_incorrect_pairs.sort((a, b) => a.serial_no - b.serial_no)
                this.reorder_correct_pairs =  this.question.reorder_correct_pairs.sort((a, b) => a.serial_no - b.serial_no)
                this.question_options_hiw =  this.question.question_options.sort((a, b) => a.serial_no - b.serial_no)
                let text = this.question.title.replace(/(<p[^>]+>|<p>|<\/p>)/g, "");
                this.fillInGap = text.replace(/ (\/)?\[[^\]]*]/g, "<span class='fil_in_gap_question'></span>")  
        },
        PropsValue() {
          this.question.question_type.map(item => console.log("gg",item));
        },
    },
}
</script>
<style scoped>
.ck-content {
    height: auto;
}
.copy {
  display: flex;
  gap: 2rem;
}
.item {
  flex: 1;
}
.tag-input_field{
  display: inline-block; 
}

</style>
