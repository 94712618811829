<template>
    <div class="" v-if="video_resource_path">
        <span v-if="video_resource_path.match('youtube')">
            <iframe 
              id="player" 
              type="text/html" 
              style="width: 100%;"
              :style="{height: height+'vh !important'}"
              :src="videoUrl(video_resource_path)"
              frameborder="0" class="rounded"
            >
            </iframe>
        </span>  
        <span v-if="!video_resource_path.match('youtube')">
            <video controls class="rounded w-100p" :style="{height: height+'vh !important'}">
              <source :src="video_resource_path" type="video/mp4">
              Your browser does not support HTML5 video.
            </video>
        </span>
    </div>
</template>

<script>
import { getEmbeddedUrl } from "@/utils/videoHelper";
export default {
    components: {
       
    },
    props:{
      quiz: {
        type: Object,
      },
      height: {
        type: Number,
        default: 25,
      },
      video_resource_path: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        // video_resource_path: 'https://www.youtube.com/watch?v=NiQVN_YeWSw&list=RDNiQVN_YeWSw&start_radio=1',
      }
    },
    methods: {
      videoUrl(url) {
        return getEmbeddedUrl(url);
      },
    }
}
</script>