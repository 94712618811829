<template>
  <div class="outline-gray-400 rounded-md p-6 flex flex-col justify-between h-full">
    <InputFieldComponent
      label="Practice Test Name"
      label-class="text-base"
      placeholder="SPECIAL PRACTICE TEST"
      v-model.trim="form.title"
      class="w-50p"
    />
    
    <div class="mt-6" v-if="type == 'mock-test'">
      <label class="text-uppercase font-bold block text-base">Choose Mock Type</label>
      <RadioButtonComponent
        label-class="text-lg text-uppercase"
        :items="[
          {id:'FULL_MOCK', label: 'Full Mock'},
          {id:'SECTION_WISE_MOCK', label: 'Section Wise Mock'},
          {id:'PARTIAL_MOCK', label: 'Partial / Mini Mock'},
        ]"
        v-model.trim="form.type"
      />
    </div>

    <div class="mt-6" v-if="form.type == 'SECTION_WISE_MOCK'">
      <label class="text-uppercase font-bold block text-base">Choose Section</label>
      <RadioButtonComponent
        label-class="text-lg text-uppercase"
        :items="sectionList"
        v-model.trim="section"
      />
    </div>

    <template v-if="type == 'pte-practice' || form.type == 'PARTIAL_MOCK'">
      <div class="mt-6">
        <label class="text-uppercase font-bold block text-base">Choose Section</label>
        <CheckboxComponent
          :label-outline="false"
          :block="false"
          label-class="text-lg text-uppercase"
          :items="sectionList"
          v-model.trim="form.sections"
        />
      </div>
    </template>

    <div v-if="type == 'mock-test'">
        <TextAreaComponent
          label="Start Message"
          label-class="text-base"
          v-model.trim="form.start_message"
        />
        <TextAreaComponent
          label="End Message"
          label-class="text-base"
          v-model.trim="form.end_message"
        />
    </div>

    <TextAreaComponent
      label="Short Note"
      label-class="text-base"
      v-model.trim="form.note"
    />

    <div class="flex justify-end mt-4">
      <md-button @click="formClear" class="text-uppercase">Clear all</md-button>
      <Button
        :loading="loading"
        class="bg-victoria rounded text-white mr-0"
        label="Next"
        type="button"
        @click="submit"
        >
      </Button>
    </div>
  </div>
</template>

<script>
import {
  Button,
  InputFieldComponent,
  TextAreaComponent,
  RadioButtonComponent,
  CheckboxComponent
  } from "@/components"
import { mapActions, mapMutations, mapGetters } from 'vuex';

export default {
  name: "TestInformationView",
  components: {
    Button,
    RadioButtonComponent,
    InputFieldComponent,
    CheckboxComponent,
    TextAreaComponent
  },
  data() {
    return {
      form: {
        id: '',
        start_message: '',
        end_message: '',
        note: '',
        type: "FULL_MOCK",
        title: '',
        instruction: "",
        duration : "10",
        create_type: "MANUAL",
        active: 0,
        sections: [],
        times: [],  
        questions: [],  
      },
      section: '',
      // questions_type: '',  
      loading: false,
      handleSection: '',
      sectionList: [
        {id:'SPEAKING', label: 'Speaking'},
        {id:'WRITING', label: 'Writing'},
        {id:'READING', label: 'Reading'},
        {id:'LISTENING', label: 'Listening'}
      ],
    }
  },
  computed: {
    ...mapGetters({
      getQuestionTypes: "question/getQuestionTypes"
    }),
    type() {
      return this.$route.query.type
    },
    items() {
      return this.getQuestionTypes.map(item => {
        item.label = item.name;
        return item;
      });
    },
  },
  watch: {
    section(value) {
      if(this.type == 'pte-practice') {
        this.actGetQuestionTypes(`?section=${value}`);
      }

      this.form.sections = [
        {section: value}
      ];
    }
  },
  methods: {
    ...mapMutations({
      setShowSnackbar: "setShowSnackbar"
    }),
    ...mapActions({
      actGetQuestionTypes: "question/actGetQuestionTypes",
      actTestCreationSave: "testCreation/actTestCreationSave",
      actTestStepUpdate: "testCreation/actTestStepUpdate",
    }),
    submit() {
      this.loading = true;

      if(this.form.type == 'PARTIAL_MOCK' || this.$route.query.type == 'pte-practice') {
        this.form.sections = this.form.sections.map(item => {
          return {
            section: item
          }
        });
        console.log("pmock",this.form.sections)
      }

      if(this.$route.query.type == 'pte-practice'){
        this.form.type = "PTE_PRACTICE";
      }
      
      if(this.form.type == 'FULL_MOCK') {
        let fullSections = ['READING', 'WRITING', 'SPEAKING', 'LISTENING']
        this.form.sections = fullSections.map(item => {
          return {
            section: item
          }
        })
        console.log("mock",this.form.sections)
      }

      delete this.form.section;

      if(this.form.id) {
        this.formUpdate();
      } else {
        this.formCreate();
      }
    },
    formCreate() {
      delete this.form.id
        
      this.actTestCreationSave(this.form)
      .then(({sections, details, message}) => {
        this.responseDataSetAndRedirectRoute(sections, details, message);
      }).catch(error => {
        console.log(error);
      }).finally(() => this.loading = false);
    },
    formUpdate() {
      this.actTestStepUpdate({testId: this.form.id, payload: this.form})
      .then(({sections, details, message}) => {
        this.responseDataSetAndRedirectRoute(sections, details, message);
      }).catch(error => {
        console.log(error);
      }).finally(() => this.loading = false);
    },
    responseDataSetAndRedirectRoute(sections, details, message) {
      this.$router.push({
        name: 'testCreation.timeSet',
        query: {type: this.type}
        
      });

      this.setShowSnackbar(message);

      localStorage.setItem('test', JSON.stringify(details));
      localStorage.setItem('selectedSections', JSON.stringify(sections));
      
      localStorage.removeItem('selectedSectionsUpdated');
      // if(!this.form.times.length) {
      // } else {
      //   localStorage.setItem('selectedSectionsUpdated', JSON.stringify(sections));
      // }
    },
    formClear() {
      let kyes = ['title', 'start_message', 'end_message', 'note'];
      kyes.forEach(key => this.form[key] = '');
      this.form.type = 'FULL_MOCK'
    },
    setData(data) {
      if(data) {
        this.form.id = data.id;
        this.form.title = data.title;
        this.form.note = data.note;
        this.form.sections = data.sections.map((item) => item.section);

        if(data?.times?.length) {
          this.form.times = data.times;
        }
      }
    }
  },
  mounted() {
    const data = JSON.parse(localStorage.getItem('test'));
    this.setData(data);
  },
  created() {
    this.actGetQuestionTypes(`?section=speaking`);
  }
}
</script>