
const readingData = []

const titles = ['Population of Canada', 'Our beautiful Bangladesh', 'We are believing the situation', 'Large changes of environment', 'New world going to dead', 'Technology is power', 'English is our second language']
const status = ['All','active', 'pending', 'cancelled', 'running'];


for (let i = 0; i < 100; i++) {
  readingData.push({
    id: i,
    qId: 'Q10'+i,
    title: titles[Math.floor(Math.random() * 6)],
    item: 'MCQSA',
    bulk: [1,5,8,9,10,16].includes(i),
    usedFor: 'PTE2 | Practice 4 | Mock 2',
    highFrequency: true,
    createdAt: '25 May, 2020, 9:15am',
    createdBy: 'Kazi Shahin',
    status: status[Math.floor(Math.random() * 5)]
  });
}


const questionTypes = [
  {id: 1,  group: 'listening', name: 'Write From Dictation', component: 'WriteFromDictation', slug: 'write-from-dictation'},
  {id: 2,  group: 'writing', name: 'Summarization', component: 'Summarization', slug: 'summarization'},
  {id: 3,  group: 'listening', name: 'Summarize Spoken Test', component: 'SummarizeSpokenTest', slug: 'summarize-spoken-test'},
  {id: 4,  group: 'listening', name: 'Select Missing Word', component: 'SelectMissingWord', slug: 'select-missing-word'},
  {id: 5,  group: 'speaking', name: 'Retell Lecture', component: 'RetellLecture', slug: 'retell-lecture'},
  {id: 6,  group: 'speaking', name: 'Repeat Sentence', component: 'RepeatSentence', slug: 'repeat-sentence'},
  {id: 7,  group: 'reading', name: 'Re-Order Paragraph', component: 'ReOrderParagraph', slug: 're-order-paragraph'},
  {id: 8,  group: 'speaking', name: 'Read Aloud', component: 'ReadAloud', slug: 'read-aloud'},
  {id: 9,  group: 'listening', name: 'MCQ - Single Answers', component: 'MCQSingleAnswers', slug: 'mcq-single-answers'},
  {id: 10, group: 'listening',  name: 'MCQ - Multiple Answers', component: 'MCQMultipleAnswers', slug: 'mcq-multiple-answer'},
  {id: 11, group: 'reading',  name: 'MCQ - Choose Single Answer', component: 'MCQChooseSingleAnswer', slug: 'mcq-choose-single-answer'},
  {id: 12, group: 'reading',  name: 'MCQ - Choose Multiple Answer', component: 'MCQChooseMultipleAnswer', slug: 'mcq-choose-multiple-answer'},
  {id: 13, group: 'listening',  name: 'Fill in the Gap', component: 'FillInTheGap', slug: 'fill-in-the-gap'},
  {id: 14, group: 'listening',  name: 'Highlight Incorrect Word', component: 'HighlightIncorrectWord', slug: 'highlight-incorrect-word'},
  {id: 15, group: 'listening',  name: 'Highlight Correct Summery', component: 'HighlightCorrectSummery', slug: 'highlight-correct-summery'},
  {id: 16, group: 'reading',  name: 'Fill in the Gap - Drag & Drop', component: 'FillInTheGapDragAndDrop', slug: 'fill-in-the-gap-drag-and-drop'},
  {id: 17, group: 'reading',  name: 'Fill in the Gap - Drop Down', component: 'FillInTheGapDropDown', slug: 'fill-in-the-gap-drop-down'},
  {id: 18, group: 'writing',  name: 'Writing Essay', component: 'WritingEssay', slug: 'writing-essay'},
  {id: 19, group: 'speaking',  name: 'Describe Image', component: 'DescribeImage', slug: 'describe-image'},
  {id: 20, group: 'speaking',  name: 'Answer Short Question', component: 'AnswerShortQuestion', slug: 'answer-short-question'},
];


const mainNavigation = [
  { id: 1, icon: 'extension', name: 'PTE Question', path: 'pte-question.reading' },
  { id: 2, icon: 'swap_vertical_circle', name: 'PTE Quiz', path: 'teacher.pteQuiz' },
]

const masterNavigation = [
  { id: 1, icon: 'extension', name: 'PTE Question', path: 'master-pte-question.reading' },
  { id: 2, icon: 'swap_vertical_circle', name: 'PTE Quiz', path: 'trainer.pteQuiz' },
]

export default {
    readingData,
    status,
    questionTypes,
    mainNavigation,
    masterNavigation
}