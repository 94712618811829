<template>
    <div class="class-information-view outline-gray-400 rounded-md p-6 flex flex-col justify-between h-full">
		<InputFieldComponent
			label="Online Class Name"
			label-class="text-base mb-4 text-capitalize font-semibold"
			placeholder="Write Here.."
			v-model.trim="form.title"
			:classes="['border', 'border-solid', 'rounded-md', 'border-gray-400']"
			class="w-50p"
			:inputClass="'p-2 font-semibold text-italic'"
			:message="!$v.form.title.required && $v.form.title.$dirty ? 'Field is required' : null"
		/>
            
		<div class="block mt-6">
			<div class="md-layout-item md-size-17 mb-4">
				<p class="text-capitalize text-base font-semibold">Add Tags</p>
			</div>

			<div class="md-layout-item md-size-65">
				<TagInput 
					v-model.trim="form.tags"
					:roundedClass="'rounded'"
				/>
			</div>
		</div>

		<div class="mt-6 class-h-200">
			<TextEditordrag
				v-model.trim="form.description"
				class="text-editor-rounded text-base"
				label="Class Description"
				labelClass="font-semibold text-base text-capitalize mb-4 text-base"
				:message="!$v.form.description.required && $v.form.description.$dirty ? 'Field is required' : null"
			/>
		</div>
		
		<div class="flex flex-col mt-6 w-60p">
			<label class="inline-block text-base text-capitalize font-semibold mb-1">Upload Class Banner Image ( Optical Dimension 3200 X 410 px )</label>
			
			<div class="flex align-center my-2">
				<!-- {{ form.bannerImage }} -->
				<FileInputComponent 
					v-model="form.bannerImage" 
					:isUpload="true"
					:path="'online-class/banner'"
					placeholder="Browse Here" 
					class="basis rounde-3" 
					:parentClasses="['c-fileInput-parent']"
					:btnClass="['c-fileInput-button text-italic text-sm']"
					:message="!$v.form.bannerImage.required && $v.form.bannerImage.$dirty ? 'Field is required' : null"
				/>
			</div>
		</div>

		<div class="mt-6 md-layout ">
			<div class="md-layout-item w-55p mr-6">
				<label class="inline-block text-base text-capitalize font-semibold mb-4 ml-4">Import Intro Video</label>
				<div
					style="height: 250px"
					class="pb-10 py-6 px-3 flex flex-col justify-between rounded outline-gray-400"
				>
					<videoUploader 
						@input="inputVideoFile" 
						placeholder="Upload Video" 
						icon=""
						classes="bg-algal text-white rounded-lg font-semibold"
					/>
					
					<div class="relative flex align-center">
						<InputFieldComponent 
							v-model.trim="form.videoFile" 
							class="w-100p"
							placeholder="Write URL" 
							:inputClass="'p-3 pl-8 font-semibold text-italic'"
						/>

						<span class="text-algal material-icons mr-1 absolute left-10">
							public
						</span> 
					</div>

					<div class="pointer flex justify-center font-semibold rounded text-algal border border-solid border-algal px-5 py-2 text-center btn-background-transparent">
						<span class="material-icons mr-1">
							drive_file_move
						</span> 
						<span>Import from portal</span>
					</div>
				</div>
				<b class="text-danger">{{ !$v.form.videoFile.required && $v.form.videoFile.$dirty ? 'Field is required' : null }}</b>
			</div>

			<div class="md-layout-item">
				<label class="inline-block text-base text-capitalize font-semibold mb-4 ml-4">Preview Intro Video</label>
				<div
					style="height: 250px !important"
					class="rounded outline-gray-400 w-100p"
				>
					<Videos :height="27" v-if="form.videoFile" id="video-preview" class="w-100p" controls v-show="form.videoFile != ''" :src ="form.videoFile" :video_resource_path="form.videoFile"/>
				</div>
			</div>
		</div>

		<div class="flex flex-col mt-6 w-60p">
			<label class="inline-block text-base text-capitalize font-semibold mb-1">Upload Thumbnail (Optical Dimension 320 X 340 px)</label>
			
			<div class="flex align-center my-2">
				<FileInputComponent 
					v-model="form.thumbnail" 
					:path="'online-class/thumbnail'"
					:isUpload="true"
					placeholder="Browse Here" 
					class="basis" 
					:parentClasses="['c-fileInput-parent']"
					:btnClass="['c-fileInput-button text-italic text-sm']"
					:message="!$v.form.thumbnail.required && $v.form.thumbnail.$dirty ? 'Field is required' : null"
				/>
			</div>
		</div>

		<div class="relative flex align-center">
			<md-switch v-model="form.isFeatured" class="md-primary">
				<span v-if="form.isFeatured" class="font-semibold text-sm">Make This Class Featured</span>
			</md-switch>

			<span v-if="!form.isFeatured">
				<span class="font-semibold text-sm text-gray-500">
					<span>
						Publish After
					</span>
					<span class="h-3">
						<SelectComponent 
							:placeholder="false" 
							:items="times"
							v-model="selectedPublishTime"
							class="mx-2 font-bold c-select-component" 
							:classes="['py-2']" 
						/>
					</span>
					<span>Hours Of The Opening Of Previous Chapter</span>
				</span> 
			</span>
		</div>

		<div class="my-6">
			<div class="md-layout">
				<div class="pb-6 px-3 flex flex-col md-layout-item md-size-55">
					<label class="font-semibold block text-base">Select Class Properties (Max 2)</label>
					<CheckboxComponent
						:label-outline="false"
						:block="true"
						titleKey="title"
						label-class="text-lg text-uppercase"
						:items="classProperties"
						:isShowBadge="true"
						@input="onUpdateFlags"
						class="c-checkbox"
						v-model.trim="form.sections"
						:maxLimit="2"
					/>
				</div>

				<div style="min-height: 280px;" class="pr-3 pl-2 py-2  rounded relative outline-gray-400 md-layout-item md-size-35">
					<div class="overflow-hidden relative" style="overflow: hidden; display: inline-block;">
						<BannerFlag 
							style="transform: rotate(312deg);
								top:11%;
								left: -33%;
							" 
							class="absolute"
							:flags="flags"
							titleKey="title"
							colorKey="color_code"
						/>
						<img class="w-100p" src="@/assets/images/dummy-overview-class.png" alt="">
					</div>
				</div>
			</div>
		</div>

		<div class="flex justify-end mt-4 align-center">
			<md-button 
				v-show="!classInfo.id"
				:disabled="loading" 
				class="px-5 py-2 h-12 text-base text-transform-none font-semibold text-gray-500 rounded-lg"
				@click="onClearAll()"
			>
				Clear all 
			</md-button>
			
			<Button
				class="h-12 pointer bg-algal pointer rounded text-white px-5 py-2 b-0 h-auto text-base font-semibold"
				type="button"
				label="Save & Next"
				@click.native="onSubmit()"
				:loading="loading"
			>
				Save & Next
			</Button>
		</div>
    </div>
</template>
  
<script>
import {
    InputFieldComponent,
    CheckboxComponent,
	SelectComponent,
	Button,
    } from "@/components";
import TagInput from "@/components/atom/form/TagInput";
import TextEditordrag from "@/components/atom/form/TextEditor";
import FileInputComponent from "@/components/atom/form/FileInputComponent";
import videoUploader from "@/components/atom/media/video/videoUploader.vue";
import BannerFlag from "@/components/atom/BannerFlag";
import { mapActions, mapMutations, mapGetters } from 'vuex';
import { required } from "vuelidate/lib/validators";
import Videos from "@/components/molecule/online-class/student/Video.vue";

export default {
    name: "TestInformationView",

    components: {
		TagInput,
		InputFieldComponent,
		CheckboxComponent,
		TextEditordrag,
		FileInputComponent,
		videoUploader,
		BannerFlag,
		SelectComponent,
		Button,
		Videos
    },

    data() {
		return {
			form: {
				title: '',
				sections: [],
				times: [],  
				tags: [],
				isFeatured: true,
				bannerImage: null,
				thumbnail: null,
				videoFile: null,
				linkUrl: null,
				description: '',
			},
			selectedPublishTime: 1,
			section: '',
			loading: false,
			flags: [],
			classProperties: [],
			times: [
				{id: 1, name: '1'},
				{id: 2, name: '10'},
				{id: 3, name: '15'},
				{id: 4, name: '20'},
				{id: 5, name: '25'},
			]
		}
    },

    computed: {
		...mapGetters({
			classInfo: "onlineClass/getClassInfo",
			getRibbonses: "onlineClass/getRibbonses",
		}),
		type() {
			return this.$route.query.type
		},
    },
    
    methods: {
		...mapMutations({
			setShowSnackbar: "setShowSnackbar",
			setClassInfo: "onlineClass/setClassInfo",
		}),
		...mapActions({
			onOnlineClassBasicCreationSave: "onlineClass/onOnlineClassBasicCreationSave",
			fetchAllOnlineClassRibbons: "onlineClass/fetchAllOnlineClassRibbons",
			initialClassInfo: "onlineClass/initialClassInfo",
		}),
		inputVideoFile(file) {
			this.form.videoFile = file;
		},

		onUpdateFlags(value) {
			let selectedFlags = [];
			if(value && value.length <= 2) {
				value.forEach((v) => {
					selectedFlags.push(this.classProperties.filter((c) => c.id == v)[0]);
				});

				this.flags = selectedFlags.filter((flag) => flag != undefined);
			}
		},

		onClearAll() {
			this.form = {
				title: '',
				sections: [],
				times: [],  
				tags: [],
				isFeatured: true,
				bannerImage: null,
				thumbnail: null,
				videoFile: null,
				linkUrl: '',
				description: '',
			}
		},
		
		onSubmit() {
			this.$v.$touch();
			if(this.$v.$invalid) return;
			
			this.loading = true;

			let formData = {
				id:this.classInfo.id ? this.classInfo.id : '',
				title: this.form.title,
				tags: this.form.tags,
				description: this.form.description,
				banner: null,
				intro_video: this.form.videoFile,
				thumbnail: null,
				is_featured: this.form.isFeatured,
				properties: [],
			}

			if(this.flags && this.flags.length) {
				this.flags.forEach((flag) => {
					formData.properties.push(flag.id);
				});
			}

			if(this.form.bannerImage && this.form.bannerImage.name) {
				formData.banner = this.form.bannerImage.name;
			} else if(this.form.bannerImage) {
				formData.banner = this.form.bannerImage;
			}

			if(this.form.thumbnail && this.form.thumbnail.name) {
				formData.thumbnail = this.form.thumbnail.name;
			} else if(this.form.thumbnail) {
				formData.thumbnail = this.form.thumbnail;
			}


			this.onOnlineClassBasicCreationSave(formData)
			.then((response) => {
				localStorage.setItem('classBasicInfo', JSON.stringify(response.data));
				this.$router.push({
					name: 'onlineClassCreation.whyImportant'
				})
				this.setClassInfo(response.data);
				this.setShowSnackbar(response.message);
			})
			.catch((error) => {
				console.log("error", error);
				this.setShowSnackbar(error);
			})
			.finally(() => this.loading = false);

		}
    },
	validations: {
		form: {
			title: {required},
			description: {required},
			bannerImage: {required},
			thumbnail: {required},
			videoFile: {required},
		},
	},
	watch:{
		getRibbonses: {
			handler(val) {
				if(val && val.length) {
					this.classProperties = val;
					if(this.form.sections && this.form.sections.length) {
						this.onUpdateFlags(this.form.sections);
					}
				}
			},
			deep: true,
		},
		classInfo: {
			handler(val) {
				let data = JSON.parse(JSON.stringify(val));
				if(data) {
					this.form.title = data.title;
					this.form.tags = data.tags;
					setTimeout(() => {
						this.form.description = data.description;
					})
					this.form.bannerImage = data.banner;
					this.form.videoFile = data.intro_video;
					this.form.thumbnail = data.thumbnail;
					this.form.isFeatured = data.is_featured ? true : false;
					this.form.sections = data.properties;
				}
			},
			deep: true
		}
	},
	created() {
		this.fetchAllOnlineClassRibbons();
		this.initialClassInfo();
	}
  }
  </script>

<style lang="scss">
.class-information-view {
	.c-select-component {
		.md-icon{
			bottom: 22% !important;
		}
	}
}
</style>