<template>
    <section class="mt-6">
        <InputFieldComponent
            label="Name Of The Module"
            label-class="text-base mb-4 text-capitalize font-semibold"
            placeholder="Write Here.."
            v-model.trim="value.title"
            :classes="['border', 'border-solid', 'rounded-md', 'border-gray-400']"
            class="w-50p"
            :inputClass="'p-2 font-semibold text-base'"
            :message="!$v.value.title.required && $v.value.title.$dirty ? 'Field is required' : null"
        />

        <div class="mt-6 class-h-200">
            <TextEditor
                v-model="value.description"
                class="text-editor-rounded text-base"
                label="Module Description"
                labelClass="font-semibold text-base text-capitalize mb-4 text-base"
                :message="!$v.value.description.required && $v.value.description.$dirty ? 'Field is required' : null"
            />
        </div>

        <div class="mt-4">
            <label
                class="mb-1 ml-8 pr-4 text-base text-capitalize font-bold block"
            >
                Chapters
            </label>

            <ChapterList  
                class="mt-4" 
                v-model="value.chapters" 
                @on-row-add="onRowAdd" 
                @on-row-delete="onRowDelete"
                ref="chapterList"
            />
        </div>

        <div class="flex justify-end mt-4 align-center">
			<md-button 
                class="px-5 text-base text-capitalize font-semibold h-12 text-gray-500"
                @click="goBack"
            >
                Back
            </md-button>
			<Button
                class="bg-algal rounded text-white px-5 py-1 b-0 h-12 text-lg font-semibold text-capitalize"
                type="button"
                :loading="loading"
                label="Save & Next"
                @click.native="onSubmit()"
            >
                Save & Next
            </Button>
        </div>
    </section>
</template>

<script>
import { InputFieldComponent, Button } from "@/components";
import TextEditor from "@/components/atom/form/TextEditor";
import ChapterList from "@/components/molecule/online-class/chapter/ChapterList.vue";
import { mapMutations, mapGetters, mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";

export default {
    name: 'ModuleInformationView',
    components: {
        Button,
        InputFieldComponent,
        TextEditor,
        ChapterList
    },
    props:{
        value: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            lastIndex: 0,
            loading: false,
        }
    },
    computed: {
		...mapGetters({
			getModules: "onlineClass/getModules",
			getModule: "onlineClass/getModule",
            getClassInfo: "onlineClass/getClassInfo",
		}),
    },
    methods: {
        ...mapMutations({
            setShowSnackbar: "setShowSnackbar",
			setModules: "onlineClass/setModules",
            setClassInfo: "onlineClass/setClassInfo",
		}),
        ...mapActions({
			onOnlineClassModuleCreation: "onlineClass/onOnlineClassModuleCreation",
		}),
        onRowAdd() {
            let moduleIndex = this.getModules.findIndex((modul) => modul.name == this.value.name);

            let chapters = [...this.value.chapters];

            if(moduleIndex > -1) {
                let modules = JSON.parse(JSON.stringify(this.getModules));
                chapters.push({
                    name: '',
                    lastIndex: this.lastIndex + 1,
                });

                modules[moduleIndex].chapters = chapters;

                this.setModules(modules);
                this.lastIndex++;
            }
        },
        onRowDelete(i) {
            if (i > -1) {
                let moduleIndex = this.getModules.findIndex((modul) => modul.name == this.value.name);
                let chapters = [...this.value.chapters];

                if(moduleIndex > -1) {
                    let modules = JSON.parse(JSON.stringify(this.getModules));
                    chapters.splice(i, 1);
                    modules[moduleIndex].chapters = chapters;

                    this.setModules(modules);
                }
            }
        },
        goBack() {
            this.$router.push({
                name: 'onlineClassCreation.faq',
            });
        },
        onSubmit() {
            this.$v.$touch();
            this.$refs.chapterList.onValidate();
			if(this.$v.$invalid) {
                this.setShowSnackbar('Please Fill The Module field');
                return;
            } 

            let index = this.getModules.findIndex((val) => val.title === this.getModule.title);
            if(index > -1 && this.value) {
                let cloneModules = this.getModules;
                cloneModules[index] = this.value;
                this.setModules(cloneModules);
            }

            let totalModules = this.getModules.length;
            let selectedModuleIndex = this.getModules.findIndex((mo) => mo.title === this.value.title);
            
            if(selectedModuleIndex > -1 && totalModules > selectedModuleIndex + 1) {

                this.$emit('change-tab', this.getModules[selectedModuleIndex + 1].title);
                return
            } else {
                let index = this.getModules.findIndex((val) => val.title === this.value.title);
				if(index > -1 && this.value) {
					let cloneModules = JSON.parse(JSON.stringify(this.getModules));
					cloneModules[index] = this.value;
					this.setModules(cloneModules);
				}
            }

            this.loading = true;
            
            let data = {
                id: this.getClassInfo.id,
                modules: [],
            }


            this.getModules.forEach((moduleItem) => {
                let moduleData = {
                    title: moduleItem.title,
                    description: moduleItem.description,
                    chapters: [],
                }

                if(moduleItem.id){
                    moduleData.id = ''; 
                    moduleData.id = moduleItem.id;
                }

                moduleItem.chapters.forEach((chapter) => {
                    let chapterData = {
                        title: chapter.title,
                    };

                    if(chapter.id){
                        chapterData.id = ''; 
                        chapterData.id = chapter.id;
                    } 
                    moduleData.chapters.push(chapterData)
                });

                data.modules.push(moduleData);
            });

            this.onOnlineClassModuleCreation(data)
            .then((response) => {
                this.setClassInfo(response.data);
                this.setModules(response.data.modules);
                this.setShowSnackbar(response.message);

                localStorage.setItem('classBasicInfo', JSON.stringify(response.data));
                
                this.$router.push({
                    name: 'onlineClassCreation.chapterDetails'
                });

			})
			.catch((error) => {
				console.log("error", error);
				this.setShowSnackbar(error.message);
			})
			.finally(() => this.loading = false);

            // this.$router.push({
            //     name: 'onlineClassCreation.chapterDetails'
            // });

		},
    },
    validations: {
		value: {
			title: {required},
			description: {required},
            chapters: {
                $each:{
                    title: {required},
                }
            }
		},
	},
}
</script>


<style lang="scss">
.tab-item-active {
    font-weight: bold;
    color: #0000 !important;
    border-bottom: 2px solid #661dd0 !important;
    margin-bottom: -2px;
}
</style>