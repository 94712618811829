<template>
    <div class="chapter-parts">
        <md-divider class="my-10"></md-divider>

        <div class="md-layout-item flex justify-between mr-6">
            <div class="flex">
                <md-icon>menu</md-icon>
                <h2 v-if="!isEditable" class="mx-3 text-2xl font-semibold">{{ value.title }}</h2>

                <InputFieldComponent
                    v-else
                    placeholder="Part"
                    v-model.trim="value.title"
                    class="w-100p"
                    :inputClass="'text-2xl font-semibold py-2 px-2'"
                    :classes="['mx-3', 'border', 'border-solid', 'rounded', 'border-gray-400', 'input-box-shadow', 'rounded-lg']"
                />

                <md-icon  v-if="!isEditable" @click.native="isEditable = true" class="text-sm font-bold pointer">border_color</md-icon>
            </div>
            
            <div class="flex align-center">
                <Button 
                    v-if="isEditable"
                    label="Save" 
                    type="submit" 
                    class="px-1 py-1 bg-algal text-sm font-semibold text-white rounded-lg"
                    @click.native="isEditable = false"
                >
                    Save
                </Button>

                <md-icon 
                    v-if="!isOnlyItem"
                    class="text-danger text-2xl mr-1" 
                    style="cursor: pointer;"
                    @click.native="$emit('on-row-delete', index)"
                >
                    delete
                </md-icon>

                <md-icon 
                    v-if="isLastItem"
                    class="bg-algal rounded p-1 text-white pointer" 
                    @click.native="$emit('on-row-add', index)"
                >
                    add
                </md-icon>
            </div>
        </div>

        <div class="md-layout-item mx-10 mt-6">
            <PartRowItem 
                v-for="(section, i) in value.sections" 
                v-model="value.sections[i]"
                :key="i"
                :index="i"
                :is-last-item="i == value.sections.length - 1"
                :is-only-item="value.sections.length == 1"
                :type="section.type"
                @on-row-add="onRowAdd"
                @on-row-delete="onRowDelete"
            />
            
        </div>


        <div class="md-layout-item md-size-30 mx-10 mt-6">
            <SelectComponent
                v-model.trim="typeIndex"
                :items="sectionTypes"
                :placeholder="false"
                class="w-full"
                :classes="['rounded-lg h-10 text-sm']"
            />
                <!-- return-type="index"
                @getIndex="getTypeIndex" -->
        </div>
    </div>
</template>

<script>
import PartRowItem from '@/components/molecule/online-class/chapter-details/PartRowItem.vue';
import { SelectComponent, InputFieldComponent, Button } from "@/components";

export default {
    name: 'Parts',
    
    components: {
        PartRowItem,
        SelectComponent,
        InputFieldComponent,
        Button
    },

    props: {
        value: {
            type: Object,
            defaul: null,
        },
        index: {
            type: Number,
            default: -1,
        },
        isLastItem: {
            type: Boolean,
            default: false,
        },
        isOnlyItem: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            type: null,
            typeIndex: '1',
            isEditable: false,
            sectionTypes: [
                {
                    id: "1",
                    type: 'task',
                    name: 'Add Tasks',
                    component: 'Task',
                },
                {
                    id: "2",
                    type: 'material',
                    name: 'Add Materials',
                    component: 'Materials',
                },
                {
                    id: "3",
                    type: 'button',
                    name: 'Add Button',
                    component: 'Button',
                }
            ]
        }
    },
    watch: {
        typeIndex: {
            handler: function(val) {

                let index = this.sectionTypes.findIndex((s) => s.id === val);

                if(index > -1) {
                    this.type = this.sectionTypes[index].type;
                }

                let findeIndexNumber = this.value.sections.findIndex((s) => s.type == this.type);

                console.log(findeIndexNumber);

                if(findeIndexNumber < 0) {
                    let obj = {};

                    if(this.type == 'task') {
                        obj = {
                            id: "",
                            type: "task",
                            instructions: "",
                            task_type: "",
                            task_type_ids: [],
                        }
                    } else if (this.type == 'button') {
                        obj = {
                            id: "",
                            type: "button",
                            instructions: "",
                            button_name: "",
                            button_url: ""
                        }
                    } else if (this.type == 'material') {
                        obj = {
                            id: "",
                            type: "material",
                            instructions: "",
                            material_type: "",
                            material_url: "",
                        }
                    }

                    this.onRowAdd(obj);
                }
            },
            immediate: true,
            deep: true,
        },
    },
    methods: {
        getTypeIndex(index) {
            this.type = this.sectionTypes[index].type;
            // console.log(index);
            console.log(this.type);

            let findeIndexNumber = this.value.sections.findIndex((s) => s.type == this.type);

            if(findeIndexNumber < 0) {
                let obj = {};

                if(this.type == 'task') {
                    obj = {
                        id: "",
                        type: "task",
                        instructions: "",
                        task_type: "",
                        task_type_ids: [],
                    }
                } else if (this.type == 'button') {
                    obj = {
                        id: "",
                        type: "button",
                        instructions: "",
                        button_name: "",
                        button_url: ""
                    }
                } else if (this.type == 'material') {
                    obj = {
                        id: "",
                        type: "material",
                        instructions: "",
                        material_type: "",
                        material_url: "",
                    }
                }

                this.onRowAdd(obj);
            }
        },

        onRowAdd(obj) {
            const clone = JSON.parse(JSON.stringify(this.value.sections));

            clone.push(obj);

            this.value.sections = clone;
        },

        onRowDelete(i) {
            if (i > -1) {
                const clone = JSON.parse(JSON.stringify(this.value.sections));

                if (clone.length > 1) {
                    clone.splice(i, 1);
                }

                this.value.sections = clone;
            }
        },
    }
}
</script>