<template>
    <div>
      <div class="md-size-30 mb-6">
          <SelectComponent
              :items="taskTableTypes"
              :placeholder="false"
              class="w-full"
              :classes="['rounded-lg h-10 text-sm']"
              v-model.trim="type"
          />
      </div>

      <div class="outline-gray-400 rounded-md">
          <div class="mb-3 flex justify-between flex-wrap justify-md-center justify-sm-center justify-xs-center">
            <SelectComponent 
              :placeholder="false"
              :items="short" 
              class="w-64 m-2 font-bold" 
            />
            <SearchBox 
              v-model="keyword"
              placeholder="Search"
              class="w-64 p-2 m-2"
              @submit="submitSearchKeyword" 
            />
          </div>
  
          <md-divider></md-divider>
  
          <Table v-if="type === '1' && getTypeWiseQuestion && getTypeWiseQuestion.items" :items="getTypeWiseQuestion?.items">
            <template slot="row" slot-scope="{ item }" md-selectable="single">
              <md-table-cell md-sort-by="Quiz_id" md-label="Quiz_Id">{{ item?.q_s_id }}</md-table-cell>
              <md-table-cell md-label="Quiz Title">{{ item?.title }}</md-table-cell>
              <md-table-cell md-label="Number Of Quizes">{{ item?.quizzes?.length }}</md-table-cell>
              <md-table-cell md-label="Created AT">{{item.created_at ? item.created_at : ''}} </md-table-cell>
              <md-table-cell>
                <md-icon
                  class=" text-2xl pointer mr-1"
                  :class="taskTypeIds.includes(item.id) ? 'text-green' : ''"
                  @click.native="onSetTaskType(item.id)"
                >
                  check_circle
                </md-icon>
              </md-table-cell>
            </template>
          </Table>

          <Table v-if="type === '3' && getTypeWiseQuestion && getTypeWiseQuestion.items" :items="getTypeWiseQuestion?.items">
            <template slot="row" slot-scope="{ item }" md-selectable="single">
              <md-table-cell md-sort-by="t_id" md-label="PTE_Id">{{ item?.t_id }}</md-table-cell>
              <md-table-cell md-label="Quiz Title">{{ item?.title }}</md-table-cell>
              <md-table-cell md-label="Number Of Questions">{{ item?.questions?.length }}</md-table-cell>
              <md-table-cell md-label="Created AT">{{item.created_at ? item.created_at : ''}} </md-table-cell>
              <md-table-cell>
                <md-icon
                  class=" text-2xl pointer mr-1"
                  :class="taskTypeIds.includes(item.id) ? 'text-green' : ''"
                  @click.native="onSetTaskType(item.id)"
                >
                  check_circle
                </md-icon>
              </md-table-cell>
            </template>
          </Table>
      </div>

      <div class="flex justify-end my-6" v-if="getTypeWiseQuestion.meta">
        <Paginate
          :start="getTypeWiseQuestion.meta.current_page"
          :end="getTypeWiseQuestion.meta.last_page"
          :total="getTypeWiseQuestion.meta.last_page"
          :limit="getTypeWiseQuestion.meta.per_page"
          @on-start="onStart"
          @on-end="onEnd"
        />
      </div>
    </div>
</template>

<script>
import { Table, SelectComponent, Paginate } from "@/components";
import SearchBox from "@/components/atom/SearchBox";
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { query } from "@/utils/queryString";

export default {
  name: 'TaskTable',
    
  components: {
    Table,
		SelectComponent,
		SearchBox,
    Paginate
  },
  props: {
    value: {
        type: Object,
        default: null,
    }
  },

  data() {
    return {
      list: [],
      short: [
        {id: 1, name: 'SHORT BY DATE'},
      ],
      type: '1',
      taskTableTypes: [
          {
              id: '1',
              type: 'quiz',
              name: 'Add Quiz',
          },
          {
              id: '2',
              type: 'practice_test',
              name: 'Add Practice Test',
          },
          {
              id: '3',
              type: 'pte_practice',
              name: 'Add PTE Practice',
          },
      ],
      selectedType: 'quiz',
      keyword: '',
      taskTypeIds: [],
      modelData: null,
      pageLimit: 5,
    }
  },

  computed: {
    ...mapGetters({
			getTypeWiseQuestion: "onlineClass/getTypeWiseQuestion",
		}),
  },
  methods: {
      ...mapActions({
        actTypeWiseQuestion: "onlineClass/actTypeWiseQuestion",
      }),
      ...mapMutations({
        setTypeWiseQuestion: "onlineClass/setTypeWiseQuestion",
      }),
      onSetTaskType(id) {
        let index = this.taskTypeIds.findIndex((item) => item === id);

        if(index > -1) {
          this.taskTypeIds.splice(index, 1);
        } else {
          this.taskTypeIds.push(id);
        }

        this.value.task_type_ids = this.taskTypeIds;
        let tasks = [];
        this.taskTypeIds.forEach((taskId) => {
          let typeIndex = this.getTypeWiseQuestion.items.findIndex((task) => task.id === taskId);

          if(typeIndex > -1) {
            tasks.push(this.getTypeWiseQuestion.items[typeIndex]);
          }
        })
        this.value.tasks = tasks;
      },
      onStart(value) {
        if(value == '') {
          query.remove('page');
        }else {
          query.set('page', value);
        }
        query.set('limit', this.pageLimit);
        this.actTypeWiseQuestion({type:this.selectedType, query: `?${query.get()}`});
      },
      onEnd(value) {
        if(value == '') {
          query.remove('page');
        }else {
          query.set('page', value);
        }

        query.set('limit', this.pageLimit);
        this.actTypeWiseQuestion({type:this.selectedType, query: `?${query.get()}`});
      },
      submitSearchKeyword() {
        query.set('limit', this.pageLimit);
        this.actTypeWiseQuestion({type:this.selectedType, query: `?${query.get('search', this.keyword)}`});
      },
  },
  watch: {
        type: {
            handler: function(val) {
                let index = this.taskTableTypes.findIndex((tableType) => tableType.id === val);

                if(index > -1) {
                  query.set('limit', this.pageLimit);
                  
                  this.selectedType = this.taskTableTypes[index].type;
                  this.setTypeWiseQuestion({});
                  this.actTypeWiseQuestion({type: this.selectedType, query: `?${query.get()}`});
                  this.value.task_type = this.selectedType;
                  this.value.task_type_ids = [];
                  this.taskTypeIds = [];
                }
            },
            immediate: true,
            deep: true,
        },
        keyword(oldvalue, newValue) {
            query.set('page', 1);
            query.set('search', oldvalue)
            // For reseting search result
            if(oldvalue.length < newValue.length && oldvalue.length == 0) {
              this.actTypeWiseQuestion({type:this.selectedType, query: `?${query.get()}`});
            }
            // For submitting search api after 3 cherecter
            if(oldvalue.length >= 3) {
              this.actTypeWiseQuestion({type:this.selectedType, query: `?${query.get()}`});
            }
        },
        'value':{
            handler(val) {
                if(!val.task_type_ids.length && val.tasks?.length) {
                  this.taskTypeIds = val.tasks.map((task) => task.task_id);
                  this.value.task_type_ids = this.taskTypeIds;

                  let typeIndex = this.taskTableTypes.findIndex((taskTableType) => taskTableType.type === val.task_type);

                  if(typeIndex > -1) {
                    this.type = this.taskTableTypes[typeIndex].id;
                  }
                }
            },
            immediate: true,
            deep: true,
        }
    },
}
</script>