<template>
    <div class="flex flex-col justify-between" style="min-height: 65vh;">
        <NewFaqRowItem 
            v-for="(item, i) in form.faqs"
            :key="item.lastIndex"
            v-model="form.faqs[i]"
            :index="i"
            :is-last-item="i == form.faqs.length - 1"
            :is-only-item="form.faqs.length == 1"
            @on-row-add="onRowAdd"
            @on-row-delete="onRowDelete"
            class="pt-6"
            ref="newFaqRowItemRef"
        />

        <div class="flex justify-end mt-20 align-center">
            <md-button 
                class="px-5 py-2 h-12 text-base text-transform-none font-semibold text-gray-500 rounded-lg" 
                @click="goBack()"
            >
                Back
            </md-button>
            
            <Button
				class="h-12 pointer bg-algal pointer rounded text-white px-5 py-2 b-0 h-auto text-base font-semibold"
				type="button"
				label="Save & Next"
                :loading="loading"
				@click.native="onSubmit()"
			>
				Save & Next
			</Button>
        </div>
    </div>
</template>

<script>
import NewFaqRowItem from "@/views/teacher/online-class/create/faq/sub-form/NewFaqRowItem.vue";
import { Button } from "@/components";
import { mapMutations, mapGetters, mapActions } from 'vuex';
import { required } from "vuelidate/lib/validators";

export default {
    name: 'NewFaq',

    components: {
        NewFaqRowItem,
        Button
    },

    data() {
        return {
            form: {
                why_important: "",
                faqs: [],
            },
            items: [
                {
                    question: '',
                    answer: '',
                }
            ],
            lastIndex: 0,
            loading: false,
        }
    },

    computed: {
      ...mapGetters({
        getFaq: "onlineClass/getFaq",
        getClassInfo: "onlineClass/getClassInfo",
      }),
    },

    methods: {
        ...mapMutations({
          setFaq: "onlineClass/setFaq",
          setShowSnackbar: "setShowSnackbar",
        }),

        ...mapActions({
            initialClassInfo: "onlineClass/initialClassInfo",
			onOnlineClassFaqCreation: "onlineClass/onOnlineClassFaqCreation",
		}),

        onRowAdd() {
            const clone = JSON.parse(JSON.stringify(this.form.faqs));

            clone.push({
                question: '',
                answer: '',
                lastIndex: this.lastIndex + 1,
            });

            this.lastIndex++;
            this.form.faqs = clone;
        },

        onRowDelete(i) {
            if (i > -1) {
                const clone = JSON.parse(JSON.stringify(this.form.faqs));

                if (clone.length > 1) {
                    clone.splice(i, 1);
                }

                this.form.faqs = clone;
            }
        },
        goBack() {
            this.$router.push({
                name: 'onlineClassCreation.whyImportant',
            });
        },
        onSubmit() {
            let formData = {
                id: this.getClassInfo.id,
                why_important: this.form.why_important,
                faqs: [],
            };
            
            this.form.faqs.forEach((faq, index) => {
                this.$refs.newFaqRowItemRef[index].onShowValidationMessage()
                formData.faqs.push({
                    question: faq.question,
                    answer: faq.answer,
                });
            });

            this.$v.$touch();
			if(this.$v.$invalid) return

            this.loading = true;

            this.onOnlineClassFaqCreation(formData)
			.then((response) => {
                this.setFaq(response.data);
                localStorage.setItem('classBasicInfo', JSON.stringify(response.data));
                
                this.$router.push({
                    name: 'onlineClassCreation.moduleInformation'
                });
				this.setShowSnackbar(response.message);
			})
			.catch((error) => {
				console.log("error", error);
                this.setShowSnackbar(error.message);
			})
			.finally(() => this.loading = false);
		}
    },
    validations: {
		form: {
            faqs: {
                $each: {
                    question: {required},
                    answer: {required},
                }
            }
		},
	},

    // watch: {
    //   getFaq: {
    //     handler(val) {
    //       if(val) {
    //         let data = JSON.parse(JSON.stringify(val));

    //         setTimeout(() => {
    //           this.form.why_important = data.why_important;
    //         });
    //         this.form.faqs = data.faqs.length ? data.faqs : this.items;
    //       }
    //     },
    //     deep: true
    //   }
    // },
    created() {
        this.initialClassInfo();

        console.log();
        if(this.getFaq) {
            let data = JSON.parse(JSON.stringify(this.getFaq));
            
            this.form.why_important = data.why_important;
            this.form.faqs = data.faqs.length ? data.faqs : this.items;
        }
    }
}
</script>