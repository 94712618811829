<template>
    <div class="flex flex-col h-100p">
        <div class="flex flex-row justify-between">
            <InputFieldComponent
                label="FAQ Question"
                v-model.trim="value.question"
                placeholder="Write Here.."
                class="w-50p"
                :inputClass="'p-2 font-bold text-italic'"
                :classes="['border', 'border-solid', 'rounded-md', 'border-gray-400']"
                labelClass='text-base mb-4 text-capitalize font-semibold'
                :message="!$v.value.question.required && $v.value.question.$dirty ? 'Field is required' : null"
            />

            <md-icon 
                 v-if="!isOnlyItem"
                class="text-danger rounded-full p-1 text-xl mx-1" 
                style="cursor: pointer;"
                @click.native="$emit('on-row-delete', index)"
            >
                delete
            </md-icon>
        </div>

        <div class="md-layout-item md-size-75">
            <TextAreaComponent 
                label="Answer"
                v-model.trim="value.answer"
                placeholder="Write Here.."
                class="w-100p rounded-lg"
                labelClass="font-semibold text-base text-capitalize mb-4"
                :rows="5"
                :textAreaClass="['border', 'border-solid', 'rounded-md', 'border-gray-400', 'w-full', 'p-2 font-bold text-italic']"
                :message="!$v.value.answer.required && $v.value.answer.$dirty ? 'Field is required' : null"
            />
        </div>

        <p v-if="isLastItem"
            class="mt-3" 
            style="
                color: #6200EE; 
                font-weight: 600; 
                font-size: small;
                cursor: pointer;"
            @click="$emit('on-row-add')"
        > 
            <span 
                style="font-size: large;"
            >+</span>
            Add Another FAQ
        </p>
    </div>
</template>

<script>
import { InputFieldComponent, TextAreaComponent } from "@/components";
import { required } from "vuelidate/lib/validators";

export default {
    name: 'NewFaqRowItem',

    components: {
        InputFieldComponent,
        TextAreaComponent,
    },

    props: {
        value: {
            type: Object,
            default: null,
        },
        index: {
            type: Number,
            default: -1,
        },
        isLastItem: {
            type: Boolean,
            default: false,
        },
        isOnlyItem: {
            type: Boolean,
            default: true,
        },
    },
    validations: {
		value: {
			question: {required},
			answer: {required},
		},
	},
    methods: {
        onShowValidationMessage() {
            this.$v.$touch();
        }
    }
}
</script>