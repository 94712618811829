<template>
  <div class="outline-gray-400 rounded-md p-4 flex flex-col justify-between h-full">
    <div class="flex justify-between align-center pb-5" style="border-bottom: 1px solid #cbd5e0">
      <div class="flex align-center">
        <div>
          <span style="display:flex;justify-content: space-between;padding:3px">
          <h2 v-for="(item,index) in questions" :key="item.id" class="text-uppercase mr-2" v-show="index === 0" >{{ item.quiz_type_title }} </h2>
          <!-- <span class="bg-victoria text-white rounded px-1 text-capitalize mx-2">{{ getSectionWiseQuiz}}</span> -->
          </span>
        </div>
        <SearchBox
          v-model="keyword"
          placeholder="Search by question name"
          class="p-2 w-48 mx-2"
          @submit="submitSearchKeyword"
        />
      </div>
      <div class="flex align-center">
        <span class="outline-gray-400 rounded px-1 mr-2">Total Question selected {{ getCartQuestions.length }} </span>
        <span class="outline-gray-400 rounded px-1 ml-2">{{ duration }}</span>
      </div>
    </div>

    <div class="flex mt-3">
      <div class="basis">
       <Table :items="questions" hover-action @onSelect="onSelect">
          <template slot="row" slot-scope="{ item }">
            <md-table-cell md-label="SL" md-numeric style="width:40px">{{ item.sl}}</md-table-cell>
            <md-table-cell md-sort-by="q_id" md-label="QQ_ID" style="width: 70px">{{ item.quiz_id }}</md-table-cell>
            <md-table-cell md-sort-by="index" md-label="INDEX"> {{ item.index }} </md-table-cell>
            <md-table-cell md-sort-by="answer_type" md-label="ANSWER TYPE"> {{ item.answer_type }} </md-table-cell>
            <md-table-cell md-sort-by="select" md-label="Select" > 
              <span v-if="item.isSelected">
                <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24">
                  <path fill="#00df05" d="M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M17.7,9.7l-7,7C10.5,16.9,10.3,17,10,17s-0.5-0.1-0.7-0.3l-3-3c-0.4-0.4-0.4-1,0-1.4s1-0.4,1.4,0l2.3,2.3l6.3-6.3c0.4-0.4,1-0.4,1.4,0S18.1,9.3,17.7,9.7z"  />
                </svg> 
              </span> 
            </md-table-cell>
          </template>
        </Table>
      </div>
      <div @click="toggle = !toggle" style="max-width: 40px; min-width: 40px" class="bg-athens-gray center pointer">
        <md-icon>{{ (toggle) ? 'keyboard_arrow_left' : 'keyboard_arrow_right' }}</md-icon>
      </div>
      <div :class="{'hidden': toggle || getCartQuestions.length < 1}" class="flex flex-col justify-between" style="width: 350px">
        <div>
          <div class="flex align-center p-4">
          <h3 class="text-uppercase ">Added Question </h3>
          <span class="ml-2 text-sm bg-victoria text-white rounded w-4 h-4 center">{{ getCartQuestions.length }}</span>
        </div>
        <Table :items="getCartQuestions" class="selected-question-list" >
          <template slot="row" slot-scope="{ item }">
            <md-table-cell> {{item.index}} </md-table-cell>
            <md-table-cell> 
              <svg @click="removeQuestion(item)" class="h-4 w-4" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 40 40">
                <path fill="#f78f8f" d="M20,38.5C9.799,38.5,1.5,30.201,1.5,20S9.799,1.5,20,1.5S38.5,9.799,38.5,20S30.201,38.5,20,38.5z"/>
                <path fill="#c74343" d="M20,2c9.925,0,18,8.075,18,18s-8.075,18-18,18S2,29.925,2,20S10.075,2,20,2 M20,1 C9.507,1,1,9.507,1,20s8.507,19,19,19s19-8.507,19-19S30.493,1,20,1L20,1z"/>
                <path fill="#fff" d="M18.5 10H21.5V30H18.5z" transform="rotate(-134.999 20 20)"/>
                <path fill="#fff" d="M18.5 10H21.5V30H18.5z" transform="rotate(-45.001 20 20)"/>
              </svg> 
            </md-table-cell>
          </template>
        </Table>
        </div>
        <div class="text-center">
          <md-button @click="onCartClear" class="text-danger text-capitalize">Clear All</md-button>
        </div>
      </div>
    </div>
    <div class="flex justify-between mt-4">
      <Paginate
       :start="paginate.start"
       :end="10"
       :total="paginate.total"
       :limit="paginate.limit"
        v-on:start="onStart"
        v-on:end="onEnd"
      />
      <div>
        <md-button @click="onBack" class="text-uppercase rounded">Previous</md-button>

        <Button
        :loading="loading"
        class="bg-victoria rounded text-white mr-0"
        label="Next"
        type="button"
        @click="submit"
        >
      </Button>
      </div>
    </div>
  </div>
</template>

<script>
import { SearchBox, Table, Paginate, Button } from "@/components";
import data from '@/data/question-creation/data';
import { paginate } from "@/utils/paginate";
import { flatten} from 'lodash';
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex';
import { query } from "@/utils/queryString";
import { secondToTime } from '@/utils';

export default {
  components: {
    Table,
    Button,
    Paginate,
    SearchBox
  },
  data() {
    return {
      loading: false,
      keyword: '',
      toggle: true,
      item: null,
      paginate: paginate.get(),
      sections: [],
      quiz_type_Id: '',
      currentDuration: 0,
    }
  },
  computed: {
    ...mapGetters({
      getCartQuestions: "quizCreation/getCartQuestions",
      quizTypes: "question/getQuiz",
      getSectionWiseQuiz: "question/getSectionWiseQuiz"
    }),
    ...mapState({
      questions: state => state.question.sectionWiseQuiz
    }),
    duration(){
      if(this.currentDuration) {
        return secondToTime(this.currentDuration);
      }
      return "00:00";
    },
    section() {
      return JSON.parse(localStorage.getItem('SlectQuizType'));
    },
    questions() {
      return this.getSectionWiseQuiz;
    },
    type() {
      return this.$route.query.type
    },
  },
  mounted(){
    console.log(localStorage.getItem("selectedSections"))
  },
  watch: {
    keyword(oldvalue, newValue) {
        query.set('search', oldvalue)
        // For reseting search result
        let testData = JSON.parse(localStorage.getItem('quizQuestionCount'));
        let quizTypeId = localStorage.getItem('quiz_type_id');
        if(oldvalue.length < newValue.length && oldvalue.length == 0) {
          this.actSectionWiseQuiz(`?quiz_type_id=${quizTypeId}&negative_marking=${testData.negative_marking}&${query.get()}`);
          this.setSelectedQuizTypeId(quizTypeId);
        }
        // For submitting search api after 3 cherecter
        if(oldvalue.length >= 3) {
          this.actSectionWiseQuiz(`?quiz_type_id=${quizTypeId}&negative_marking=${testData.negative_marking}&${query.get()}`);
          this.setSelectedQuizTypeId(quizTypeId);
        }
    },
  },
  methods: {
    ...mapMutations({
      setCartQuestions: "quizCreation/setCartQuestions",
      setCartClear: "quizCreation/setCartClear",
      setRemoveCartQuestion: "quizCreation/setRemoveCartQuestion",
      setQuiz: "question/setQuiz",
      setSelectedQuiz: "question/setSelectedQuiz",
      setSelectedQuizTypeId: "question/setSelectedQuizTypeId",
    }),
    ...mapActions({
      actSectionWiseQuiz: "question/actSectionWiseQuiz",
      actTestStepUpdate: "quizCreation/actTestStepUpdate",
    }),
    ...mapGetters({
      quizTypes: "question/getQuiz",
      getSectionWiseQuiz: "question/getSectionWiseQuiz"
    }),

    onBack() {
      if(this.$route.name === 'quizCreation.chooseQuestion') {
        let quizTypeId = localStorage.getItem('quiz_type_id');
        let testData = JSON.parse(localStorage.getItem('quizQuestionCount'));

        let index = this.quizTypes.findIndex((type) => type.quiz_type_id === quizTypeId);

        if(index > 0 && this.quizTypes.length > index) {
          let qzTypeId = this.quizTypes[index - 1].quiz_type_id;

          this.actSectionWiseQuiz(`?quiz_type_id=${qzTypeId}&negative_marking=${testData.negative_marking}`);
          localStorage.setItem("quiz_type_id",qzTypeId);
          this.setSelectedQuizTypeId(qzTypeId);
        } else {
          this.$router.push({
            name: this.type === 'quiz-test' ? 'quizCreation.createType' : 'testCreation.createType',
            query: {type: this.type}
          });
        }
      } else {
        //will implement for testCreation
      }
    },

    submitSearchKeyword() {
      let testData = JSON.parse(localStorage.getItem('quizQuestionCount'));
      let quizTypeId = localStorage.getItem('quiz_type_id');
      this.actSectionWiseQuiz(`?quiz_type_id=${quizTypeId}&negative_marking=${testData.negative_marking}&${query.set('file_name', this.keyword)}`);
    },
    onSelect(item) {
      if(item) {
        this.toggle = false;

        let testData = JSON.parse(localStorage.getItem('quizQuestionCount'));
        let findObject = testData.times.find((time) => time.quiz_type_id === item.quiz_type_id);

        let pushedItemLength = this.getCartQuestions.filter((question) => question.quiz_type_id === item.quiz_type_id).length;

        let selectedQuantity = findObject.quantity;

        let isExist = this.getCartQuestions.find((ques) => ques.id === item.id);

        // if(findObject && selectedQuantity && pushedItemLength < selectedQuantity) {
        if(!isExist && findObject && selectedQuantity && pushedItemLength < selectedQuantity) {

          // let splitTime = Number(findObject.answer_time) / Number(findObject.quantity);
          this.currentDuration += Number(findObject.answer_time);

          testData.duration = this.currentDuration;

          let findTimeIndex = testData.times.findIndex((time) => time.quiz_type_id === item.quiz_type_id); 
          if(findTimeIndex > -1) {
            if(!testData.times[findTimeIndex].number_of_question) testData.times[findTimeIndex].number_of_question = 0;

            testData.times[findTimeIndex].number_of_question = Number(testData.times[findTimeIndex].number_of_question) + 1; 
          }

          localStorage.setItem('quizQuestionCount', JSON.stringify(testData));
          localStorage.setItem('test', JSON.stringify(testData));

          if(this.$route.query.type == "quiz-test"){
            this.setSelectedQuiz(item);
            this.setCartQuestions(item);
            
          } else {
            this.setSelectedQuiz(item);
            this.setCartQuestions(item);
          }
        } else if(!isExist && findObject && selectedQuantity === "" || selectedQuantity === null) {
          this.currentDuration += Number(findObject.answer_time);

          testData.duration = this.currentDuration;

          let findTimeIndex = testData.times.findIndex((time) => time.quiz_type_id === item.quiz_type_id); 
          if(findTimeIndex > -1) {
            if(!testData.times[findTimeIndex].number_of_question) testData.times[findTimeIndex].number_of_question = 0;

            testData.times[findTimeIndex].number_of_question = Number(testData.times[findTimeIndex].number_of_question) + 1; 
          }

          localStorage.setItem('quizQuestionCount', JSON.stringify(testData));
          localStorage.setItem('test', JSON.stringify(testData));

          if(this.$route.query.type == "quiz-test"){
            this.setSelectedQuiz(item);
            this.setCartQuestions(item);
            
          } else {
            this.setSelectedQuiz(item);
            this.setCartQuestions(item);
          }
        }
        
      }
    },
    onCartClear() {
      this.item = null;
      this.toggle = true;
      data.readingData.map(item => item.isSelected = false);
      this.setCartClear();
      this.currentDuration = 0;
      let testData = JSON.parse(localStorage.getItem('quizQuestionCount'));
      testData.duration = this.currentDuration;
      testData.times.forEach((time) => {
        if(!time.number_of_question) time.number_of_question = 0;
        time.number_of_question = 0;
      })

      localStorage.setItem('quizQuestionCount', JSON.stringify(testData));
      localStorage.setItem('test', JSON.stringify(testData));
    },  
    removeQuestion(item) {
      this.item = item;
      let testData = JSON.parse(localStorage.getItem('quizQuestionCount'));
      let findObject = testData.times.find((time) => time.quiz_type_id === item.quiz_type_id);

      if(findObject && this.currentDuration) {
        // let splitTime = Number(findObject.answer_time) / Number(findObject.quantity);
        this.currentDuration -= Number(findObject.answer_time);

        testData.duration = this.currentDuration;

        let findTimeIndex = testData.times.findIndex((time) => time.quiz_type_id === item.quiz_type_id); 
        if(findTimeIndex > -1) {
            if(!testData.times[findTimeIndex].number_of_question) testData.times[findTimeIndex].number_of_question = 0;

            testData.times[findTimeIndex].number_of_question = Number(testData.times[findTimeIndex].number_of_question) + 1; 
        }

        localStorage.setItem('quizQuestionCount', JSON.stringify(testData));
        localStorage.setItem('test', JSON.stringify(testData));
      }

      item.isSelected = false;
      this.setRemoveCartQuestion(item);
      if(this.getCartQuestions && this.getCartQuestions.length === 0) {
        this.toggle = true;
      }

    },
    onStart(value) {
      this.paginate.start -= value;
      this.paginate.end -= value;
    },
    onEnd(value) {
      this.paginate.start += value;
      this.paginate.end += value;
    },

    subNext() {
      if(this.$route.name === 'quizCreation.chooseQuestion') {
        let quizTypeId = localStorage.getItem('quiz_type_id');
        let testData = JSON.parse(localStorage.getItem('quizQuestionCount'));

        let index = this.quizTypes.findIndex((type) => type.quiz_type_id === quizTypeId);

        if(index > -1 && this.quizTypes.length > index + 1) {
          let qzTypeId = this.quizTypes[index + 1].quiz_type_id;

          this.actSectionWiseQuiz(`?quiz_type_id=${qzTypeId}&negative_marking=${testData.negative_marking}`);
          localStorage.setItem("quiz_type_id",qzTypeId);
          this.setSelectedQuizTypeId(qzTypeId);

          return true;
        }

        return false;
      } else {
        //will implement for testCreation

        return true;
      }
    },

    async submit() {
      if(this.subNext()) return;

      this.loading = true;
      let test = JSON.parse(localStorage.getItem('test'));
      let testData = JSON.parse(localStorage.getItem('quizQuestionCount'));
      testData.quizzes = this.getCartQuestions.map(item => item.id);
      // testData.quiz_times = testData.times;

      let response = await this.actTestStepUpdate({
        testId: test.id,
        payload: testData
      });
      localStorage.setItem('createTypeResponse',JSON.stringify(response));
      this.$router.push({
        name: 'quizCreation.review',
        query: {type: this.type}
      });
      this.loading = false;
    },
    onQuestionTypes() {
      let records = [];
      let types = JSON.parse(localStorage.getItem('selectedSections'));
      const quiz_type = types.filter(item => {
        return item.quiz_type_title === "Audio+Text";
      });
      this.quiz_type_Id = quiz_type[0].quiz_type_id
      types.forEach(item => {
        records.push(item);
      });
      return flatten(records);
    }
  },
  created() {
    this.setQuiz(this.onQuestionTypes());
    let testData = JSON.parse(localStorage.getItem('quizQuestionCount'));
    localStorage.setItem("quiz_type_id",this.quizTypes[0].quiz_type_id);
    this.actSectionWiseQuiz(`?quiz_type_id=${this.quizTypes[0].quiz_type_id}&negative_marking=${testData.negative_marking}`);
    this.setSelectedQuizTypeId(this.quizTypes[0].quiz_type_id);
    this.currentDuration = testData.duration;
  }
}
</script>