<template>
  <div>
      <div class="mt-6">
      <div class="flex align-center">
        <h3 class="text-uppercase text-primary text-lg mb-6"># {{question.index}}</h3>
      </div>
       <div>
          <div v-if="question.question_type.section == 'SPEAKING'">
            <md-content class="md-sl__font_600 md-sl__x1" v-html="question.title">
            </md-content>
          </div>
          <div v-if="question.prompt != null">
            <h4 class="text-uppercase text-primary mb-2">prompt</h4>
            <md-content v-html="question.prompt" class="md-sl__font_600 mb-4 md-sl__content_color md-content__p1 pl-3 pr-3 pt-2 pb-2">
            </md-content>
          </div>
          <md-content v-if="question.question_type.title == 'Answer Short Question'" class="md-sl__font_600 md-sl__x1 mb-4 md-sl__q_color md-content__p1 md-sl__porpule">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. 
          </md-content>
          <div v-if="question.question_type.section == 'SPEAKING' || question.question_type.section == 'LISTENING'">
              <div class="my-2" v-if="question.question_type.short_title != 'DI'">
                <p class="text-uppercase text-victoria mb-2">Audio File</p>
                <Audio :src="question.file_path"/>
              </div>
              <div class="my-2" v-if="question.question_type.short_title != 'RA'">
                <p class="text-uppercase text-victoria mb-2"> Transcript</p>
                <p class="p-1 pt-2 pb-4" v-if="question.transcript != null" v-html="question.transcript"></p>
                <p class="p-1 pt-2 pb-4" v-if="question.transcript == null">No data</p>
              </div>
          </div>
          <md-content v-if="question.question_type.title == 'Describe Image'" class="md-sl__font_600 md-sl__x1 mb-4 md-content__p1 md-sl__porpule md-image__section pt-4 pb-6">
           <img :src="question.file_path"/>
          </md-content>
          <!-- <md-content v-if="question.question_type.title == 'Answer Short Question' || question.question_type.title == 'Repeat Sentence' || question.question_type.title == 'Retell Lecture'">
             <Audio :src="question.file_path"/>
          </md-content> -->
          <md-content v-if="question.question_type.title == 'Retell Lecture'" class="md-sl__font_600 md-sl__x1 mb-4 md-content__p1 md-sl__porpule md-image__section">
          </md-content>
          <md-content v-if="question.question_type.title == 'Describe Image' || question.question_type.title == 'Repeat Sentence' || question.question_type.title == 'Answer Short Question' || question.question_type.title == 'Read Aloud' || question.question_type.title == 'Retell Lecture'">
             <Record/>
          </md-content>

          <QuestionList :question="question"/>
        </div>
    </div>
    <!-- <div class="text-lg text-italic my-4 line-height-27" v-html="question"></div> -->
    
  </div>
</template>

<script>
import QuestionList from "@/components/molecule/question/questionList.vue"
import Audio from '@/components/molecule/question/audio.vue'
import Record from '@/components/molecule/question/AudioVisualizer.vue'
export default {
    components:{
        Audio,
        Record,
        QuestionList
    },
    props: {
        question: {
           type: Object
        }
    },
    data() {
      return {
          section: '',
          question_type: []
      }
  },
}
</script>

<style>

</style>