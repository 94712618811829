<template>
    <div class="preview-container outline-gray-400 rounded-md px-6 pb-6 h-full relative">
        <section class="my-6">
            <div class="text-xl text-algal font-bold">{{ getClassInfo.title }}</div>
            <div class="mt-6 text-base font-semibold">Class Description</div>
            <p class="mt-2 text-sm line-height-27" v-html="getClassInfo.description"></p>
        </section>

        <section class="my-4">
            <div class="my-3 text-base font-semibold">Tags</div>

            <div class="flex">
                <span v-for="(tag, i) in getClassInfo.tags" :key="i" class="flex flex-row align-center  text-algal bg-trans-blue px-2 py-1 rounded ml-1 text-center font-normal">
                    {{ tag }}
                    <!-- <span class="ml-1 material-icons" style="font-size: 14px;">
                        close
                    </span> -->
                </span>
            </div>
        </section>

        <section>
            <Tab 
                v-if="items.length"
                :items="items" 
                wrapperClass="c_simple_tab_two"
                @activeTab="onSetTab"
                titleKey="title"
            >
            </Tab>
        </section>


        <section class="module-section w-100p">
            <h4 class="text-black text-xl text-bold mt-5 mb-4 flex">
                {{ selectedModule.title }}
                <span class="text-blue bg-trans-blue px-2 rounded ml-1 text-center font-normal" style="font-size: 10px;">
                    {{senitizeDurationToHour(selectedModule?.total_duration)}} Hours
                </span>
            </h4>
            
            <p class="mt-2 text-sm line-height-27" v-html="selectedModule.description"></p>
       
            <div class="bg-lite-algal border border-algal-lite border-solid  rounded-lg mt-3 py-2 px-3 text-xs rounded text-start"> 
                <span class="text-algal font-bold text-sm">Rule : </span>
                <span class="text-sm">{{ rule(selectedModule?.rules) }}</span>
            </div>
        </section>

        <section class="w-100p mt-10">
            
            <div class="flex flex-row">
                <div class="w-20p border-r border-gray-400 mr-6">
                    <ul class="md-sidebar_active_line" >
                        <li v-for="(item, index) in chapters" :key="index">
                            <span 
                                class="pointer inline-flex py-2 md-acive_icon text-capitalize ml-2 text-base text-black"
                                :class="{'tab-item-active': selectedChapter.id === item.id}"
                                @click="onSetSelectedChapter(item)"
                            >
                                {{ item.title }}
                            </span>
                        </li>
                    </ul>
                </div>

                <div class="w-80p">
                    <ChapterView :item="selectedChapter"/>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import Tab from '@/components/atom/SimpleTab.vue';
import { secondToHour } from '@/utils';
import { mapGetters, mapMutations, mapActions } from "vuex";
import ChapterView from "@/views/teacher/online-class/create/preview/ChapterView.vue";

export default {
	name: 'IndexView',

	components: {
		Tab,
        ChapterView
	},

	data() {
		return {
			chapters: [
				{name: 'Chapter 1', path: 'onlineClassCreation.preview.module.chapter', id: '1', color: '#000'},
				{name: 'Chapter 2', path: 'onlineClassCreation.preview.module.chapter', id: '2', color: '#000'},
				{name: 'Chapter 3', path: 'onlineClassCreation.preview.module.chapter', id: '3', color: '#000'},
			],
            items: [],
            selectedTab: null,  
            selectedTabIndex: -1,
			selectedModule: null,

            selectedChapter: null,
		}
	},
    computed: {
		...mapGetters({
            getModules: "onlineClass/getModules",
            getModule   : "onlineClass/getModule",
			getClassInfo: "onlineClass/getClassInfo",
		}),
    },
    methods: {
        ...mapMutations({
			setModule: "onlineClass/setModule",
			setModules: "onlineClass/setModules",
		}),
        ...mapActions({
            initialClassInfo: "onlineClass/initialClassInfo"
        }),
        onSetTab(tab) {
			let findIndexBySelectedTab = this.getModules.findIndex((val) => val.title === tab);

			if(this.selectedModule) {
				let index = this.getModules.findIndex((val) => val.title === this.selectedModule.title);
				if(index > -1 && this.selectedModule) {
					let cloneModules = this.getModules;
					cloneModules[index] = this.selectedModule;
					this.setModules(cloneModules);
				}
			}
			
			if(findIndexBySelectedTab > -1) {
				this.setModule(this.getModules[findIndexBySelectedTab]);
			}

            this.selectedTab = tab;
        },
        senitizeDurationToHour(duration) {
            return secondToHour(duration);
        },
        rule(rules) {
            if(rules.rule === 'no_rule') {
                return 'No Rules'
            } else if(rules.rule === 'publish_after') {
                return `Publish after ${rules.rule_value} hours of the opening of previous chapter`
            }
        },
        onSetSelectedChapter(chapter) {
            this.selectedChapter = chapter;
        }
    },
    watch: {
		'getModules': {
			deep: true,
            immediate: true,
			handler: function(value) {
				if(value) {
					this.items = value;
	
					if(this.selectedModule) {
						let index = this.getModules.findIndex((val) => val.title === this.selectedModule.title);
						if(index > -1) {
							this.selectedTabIndex = index;
							this.selectedModule = JSON.parse(JSON.stringify(this.getModules[index]))
						}
					}
				}
			}
		},
		getModule(value) {
            if(value) {
				let index = this.getModules.findIndex((val) => val.title === value.title);
				if(index > -1) {
					this.selectedTabIndex = index;
					this.selectedModule = JSON.parse(JSON.stringify(this.getModules[index]));
                    this.chapters = this.selectedModule.chapters;
                    this.selectedChapter = this.selectedModule.chapters[0];
				}
			}
		},
	},
    created() {
		this.initialClassInfo();

        if(this.getModules.length) {
            this.items = JSON.parse(JSON.stringify(this.getModules));
    
            this.setModule(this.items[0]);
            let index = this.getModules.findIndex((val) => val.title === this.getModule.title);
            
            if(index > -1) {
                this.selectedTabIndex = index;
                this.selectedModule = JSON.parse(JSON.stringify(this.getModules[index]));
                this.selectedChapter = this.selectedModule.chapters[0];
            }
        } else {
            this.$router.push({
                name: 'onlineClassCreation.classInformation'
            })
        }
	}
}
</script>

<style lang="scss">
.preview-container {
    .tab-item-active {
        font-weight: 500;
        color: #661dd0 !important;
        // border-bottom: 1px solid #661dd0 !important;
        margin-bottom: -2px;
    }
}
</style>