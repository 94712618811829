<template>
<div>
  <div class="md-sl__playerBody">
    <div class="md-sl__wave_body md-record_body">
      <div class="md-sl__wave_text">
        <b v-if="this.x > 0">RECORDING WILL STARTING IN <span class="md-sl__porpule">20 SEC</span></b>
      </div>
      <div id="record__visual">  
        <div class="md-pre__wafe" v-if="this.HideImg">
          <img src="../../../assets/images/wave.png"/>
        </div>
        <span class="record__timer_dt"> 00.00 / {{this.totalTime}}m</span>
        <av-media class="md-sl__wave"
          type="frequ" 
          ref-link="player"
          :media="media" 
          line-color="#762cb5" 
          :canv-width="560" 
          :fft-size="8192"
          :canv-height="120"
          :line-width="0.05"
          :frequ-lnum="150"
        />  
      </div>
      <div class="md-recording_only md-audio_st">
          <audio id="audio" controls></audio>
      </div>
    </div>
   </div>

</div>
</template>
<script>
export default {
  name: 'av-media-demo',
  data () {
    return {
      record: true,
      fileName: "",
      src: "",
      x: 50,
      totalTime: .1,
      hidePlayOtion: '',
      HideImg: true,
      media: null,
      showUploadButton: false,
      datas: ''
    }
  },
  components:{
  
  },
  mounted () {
    const audio = this.$refs.player
    const constraints = {
      audio: true,
      video: false
    }
    navigator.mediaDevices.getUserMedia(constraints)
      .then(media => {
        this.media = media
        audio.srcObject = media
    });
  },
  methods: {
    callback(data) {
      document.querySelector('#audio').setAttribute('src',data.url);
       this.$eventBus.$emit('start recording')
    },
    playRecord(){
       this.HideImg = false
    },
  },
  computed: {
    
  }
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.md-sl__wave{
    border: 1px solid #bebebe;
    border-radius: 4px;
    background: #fff;
}

.md-sl__wave_body{
    background: #bcbcbc45;
    height: 136px;
    padding: 10px;
    margin-bottom: 70px;
    border-radius: 4px ;
}
.record__timer_dt{
  position: absolute;
  right: 5px;
  top: 7px;
  color: #3c3c3ca6;
  font-size: 14px;
  font-weight: 600;
}
</style>