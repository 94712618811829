<template>
  <div id="quiz_test" class="md-sl__slide">
    <div class="md-layout-column md-sl__br">
      <div v-for="(quiz,index) in question.quizzes" :key="quiz.id">
        <div class="rounded-md p-4 pb-0 flex flex-col justify-between h-full">
          <div class="text-uppercase  bg-link-water p-6 rounded flex justify-between align-center">
            <div class="flex align-center">
              <h2 class="text-lg font-bold text-white bg-primary px-2 py-2 rounded-sm">{{ quiz.quiz_type_title }}</h2>
              <p class="text-gray-600 text-lg font-bold">
                <span class="text-primary pr-2 pl-2 ml-2 py-1 md-pte-back_prev">{{ quiz.answer_type }}</span> 
              </p>
            </div>
            <div class="flex align-center">
              <p class="text-gray-600 text-lg font-bold">
                <span class="text-primary pr-2 pl-2 ml-2 py-1 md-pte-back_prev">{{ index+1 }} of {{ question.quizzes.length }}</span> 
              </p>
            </div>
          </div>
        </div>
        <components ref="perent" :is="convertor(quiz.quiz_type_title)" :quiz="quiz"></components>
        <components ref="child" :is="convertors(quiz.answer_type)" :quiz="quiz"></components>
      </div>
    </div>
  </div>
</template>

<script>
import ArrowIcon from '@/assets/svg/arrowIcon.svg';
import Questions from "@/views/student/quiz-test/item";
  export default {
    name: 'DialogCustom',
    components:{
      ArrowIcon,
      ...Questions
    },
    props: {
      question: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
          items:[],
          MCQChooseMultipleAnswer: 'MCQChooseMultipleAnswer'
        }
    },
  computed: {
    
  },
  methods: {
    convertor(title){
      if( title === 'AudioAndImage'){
        return 'AudioAndText'; 
      } 
      if(title !== 'AudioAndImage'){
        return title.replace('+', "And"); 
      }
    },
    convertors(title){
      if(title === "MCQ-CHOOSE-MULTIPLE-ANSWER"){
        return 'MCQChooseMultipleAnswer'; 
      }
      if(title === 'MCQ-CHOOSE-SINGLE-ANSWER'){
        return 'MCQChooseSingleAnswer'; 
      } 
      if( title === 'TEXT'){
        return 'TextAnswer'
      }
    },
  },
  
};
</script>
