<template>
    <div class="flex justify-center align-center" style="min-height: 65vh;">
        Coming Soon
    </div>
</template>

<script>
    export default {
    }
</script>
