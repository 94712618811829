<template>
    <div class="outline-gray-400 rounded-md px-6 flex flex-col align-center h-full relative">
        <Tab 
			v-if="items.length"
            :items="items" 
			wrapperClass="c_simple_tab_two"
			@activeTab="onSetTab"
			titleKey="title"
			:activeTab="selectedTab"
        >
        </Tab>

        <div class="absolute right-20 top-20 z-index-999" v-if="items.length">
			<md-icon 
				class="bg-algal rounded p-1 text-white text-xl text-base mx-3 pointer" 
				@click.native="onCreateModule()"
			>
				add
			</md-icon>
        </div>
		
        <section class="pb-6 w-100p">
			<ModuleInformationView 
				v-if="getModules.length && selectedTabIndex > -1" 
				v-model="selectedModule"
				@change-tab="onSetTab"
			/>

			<div v-else class="flex justify-center align-center h-84">
				<Button
					class="pointer bg-algal rounded text-white px-5 py-1 b-0 h-12 text-lg font-semibold text-capitalize"
					type="button"
					label="Add Module"
					@click="onCreateModule()"
				>
					Add Module
				</Button>
			</div>
        </section>
		
		<Dialog :class="component">
			<components :content="content" :is="component"></components>
		</Dialog>
    </div>
</template>

<script>
// import Tab from '@/components/atom/Tab';
import Tab from '@/components/atom/SimpleTab.vue';
import { Dialog } from "@/components";
import { mapMutations, mapGetters, mapActions } from "vuex";
import ModuleForm from "@/views/teacher/online-class/create/module-info/ModuleForm.vue";
import ModuleInformationView from "@/views/teacher/online-class/create/module-info/ModulesInformationView.vue";

export default {
	name: 'IndexView',

	components: {
		Tab,
		Dialog,
		ModuleForm,
		ModuleInformationView
	},

	data() {
		return {
			component: 'ModuleForm',
			content: {},
			items: [],
			selectedTab: null,
			selectedTabIndex: -1,
			selectedModule: null,
		}
	},
	computed: {
		...mapGetters({
			getModules: "onlineClass/getModules",
			getModule: "onlineClass/getModule",
		}),
    },
	methods: {
		...mapMutations({
			dialog: "setShowDialog",
			setModule: "onlineClass/setModule",
			setModules: "onlineClass/setModules",
		}),
		...mapActions({
			initialClassInfo: "onlineClass/initialClassInfo",
		}),

		onCreateModule() {
			this.component = 'ModuleForm';
			this.dialog(true);
		},
		onSetTab(tab) {
			let findIndexBySelectedTab = this.getModules.findIndex((val) => val.title === tab);

			if(this.selectedModule) {
				let index = this.getModules.findIndex((val) => val.title === this.selectedModule.title);
				if(index > -1 && this.selectedModule) {
					let cloneModules = this.getModules;
					cloneModules[index] = this.selectedModule;
					this.setModules(cloneModules);
				}
			}
			
			if(findIndexBySelectedTab > -1) {
				this.setModule(this.getModules[findIndexBySelectedTab]);
			}

            this.selectedTab = tab;
        }
	},
	watch: {
		'getModules': {
			deep: true,
            immediate: true,
			handler: function(value) {
				if(value) {
					this.items = value;
	
					if(this.selectedModule) {
						let index = this.getModules.findIndex((val) => val.title === this.selectedModule.title);
						if(index > -1) {
							this.selectedTabIndex = index;
							this.selectedModule = JSON.parse(JSON.stringify(this.getModules[index]))
						}
					}
				}
			},
		},
		getModule(value) {
			if(value) {
				let index = this.getModules.findIndex((val) => val.title === value.title);
				if(index > -1) {
					this.selectedTabIndex = index;
					this.selectedModule = JSON.parse(JSON.stringify(this.getModules[index]))
				}
			}
		},
	},
	created() {
		this.initialClassInfo();
		this.items = JSON.parse(JSON.stringify(this.getModules));

		if(this.getModules.length > 0) {
			this.setModule(this.items[0]);
			let index = this.getModules.findIndex((val) => val.title === this.getModule.title);
			
			if(index > -1) {
				this.selectedTabIndex = index;
				this.selectedModule = JSON.parse(JSON.stringify(this.getModules[index]));
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.tab-item-active {
    font-weight: bold;
    color: #0000 !important;
    border-bottom: 2px solid #661dd0 !important;
    margin-bottom: -2px;
}
</style>