<template>
    <div class="button-instruction flex flex-col justify-between h-full">
        <TextAreaComponent
            v-if="isEditable"
            v-model.trim="value.instructions"
            class=""
            :textAreaClass="'rounded-lg w-full italic text-base'"
            :rows="6"
        />

        <p v-else class="text-sm">{{ value.instructions }}</p>

        <div class="flex mt-8" v-if="isEditable">
            <InputFieldComponent
                placeholder="Button Name"
                v-model.trim="value.button_name"
                class="w-25p italic mr-8"
                :inputClass="'text-sm p-3'"
                :classes="['border', 'border-solid', 'rounded', 'border-gray-400', 'input-box-shadow', 'rounded-lg']"
            />
            <InputFieldComponent
                placeholder="Button Url"
                v-model.trim="value.button_url"
                class="w-75p"
                :inputClass="'text-sm p-3'"
                :classes="['border', 'border-solid', 'rounded', 'border-gray-400', 'input-box-shadow', 'rounded-lg']"
            />
        </div>

        <div v-else>
            <Button 
                :label="value.button_name" 
                class="mx-0 mt-4 px-5 py-2 h-12 bg-algal text-base font-semibold text-white rounded-lg"
            >{{ value.button_name }}</Button>
        </div>
    </div>
</template>

<script>
import { InputFieldComponent, TextAreaComponent, Button } from "@/components";

export default {
    name: 'ButtonInstruction',

    components: {
        InputFieldComponent,
        TextAreaComponent,
        Button,
    },

    props: {
        isEditable: {
            type: Boolean,
            default: false
        },
        value: {
            type: Object,
            default: null,
        }
    },
}
</script>