<template>
    <div>
        <div class="StepProgress-item" :class="!isLastItem ? 'is-done' : ''">
            <div class="flex ">
                <InputFieldComponent
                    label=""
                    label-class="text-base mb-4 font-semibold text-capitalize"
                    placeholder="Name Of The Chapter"
                    v-model.trim="value.title"
                    :classes="['border', 'border-solid', 'rounded-md', 'border-gray-400']"
                    class="w-50p"
                    :inputClass="'p-2 font-semibold'"
                    :message="!$v.value.title.required && $v.value.title.$dirty ? 'Field is required' : null"
                />
        
                <md-icon 
                    v-if="!isOnlyItem && !isLastItem"
                    class=" rounded-full p-1 text-danger text-2xl text-base mx-3" 
                    style="cursor: pointer;"
                    @click.native="$emit('on-row-delete', index)"
                >
                    delete
                </md-icon>

                <md-icon 
                    v-if="isLastItem"
                    class="border-2 border-solid border-algal rounded-full p-1 text-algal text-xl text-base mx-3" 
                    style="cursor: pointer;"
                    @click.native="$emit('on-row-add', index)"
                >
                    add
                </md-icon>
            </div>
        </div>
    </div>
</template>

<script>
import { InputFieldComponent } from "@/components";
import { required } from "vuelidate/lib/validators";

export default {
    name: 'ChapterRowItem',

    components: {
        InputFieldComponent
    },
    props: {
        value: {
            type: Object,
            required: true,
        },
        index: {
            type: Number,
            default: -1,
        },
        isLastItem: {
            type: Boolean,
            default: false,
        },
        isOnlyItem: {
            type: Boolean,
            default: true,
        },
    },
    methods:{
        onValidate() {
            this.$v.$touch();
			if(this.$v.$invalid) return false;
            return true
        }
    },
    validations: {
		value: {
			title: {required},
		},
	},
}
</script>