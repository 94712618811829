<template>
  <div class="outline-gray-400 rounded-md p-6 flex flex-col justify-between h-full">
    <h2 class="text-uppercase mb-6">Number of question preset</h2>
    <div class="md-layout md-gutter">
      <div v-if="section.speaking" style="flex: 0 1 20%" class="mb-6 mt-4 md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-50">
          <h2 class="text-victoria text-base text-uppercase mb-4">Speaking</h2>
           <QuestionNumberInput
            v-for="(type, index) in section.speaking"
            :key="index"
            :item="type"
          />
      </div>

      <div v-if="section.writing" style="flex: 0 1 20%" class="mb-6 mt-4 md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-50">
        <h2 class="text-victoria text-base text-uppercase mb-4">writing</h2>
        <QuestionNumberInput
            v-for="(type, index) in section.writing"
            :key="index"
            :item="type"
          />
      </div>

      <div v-if="section.reading" style="flex: 0 1 20%" class="mb-6 mt-4 md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-50">
        <h2 class="text-victoria text-base text-uppercase mb-4">reading</h2>
        <QuestionNumberInput
            v-for="(type, index) in section.reading"
            :key="index"
            :item="type"
        />
      </div>

      <div v-if="section.listeningSingle" style="flex: 0 1 40%" class="md-layout-item md-layout md-xsmall-size-100 md-small-size-100 md-medium-size-50">
          <div class="md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-50">
        <h2 class="text-victoria text-base text-uppercase mb-4">listening</h2>
            <QuestionNumberInput
              v-for="(type, index) in section.listeningSingle"
              :key="index"
              :item="type"
              :condition="condition"
            />
            <QuestionNumberInput
              v-for="(type, index) in section.listeningMultiple"
              :key="index"
              :item="type"
              :condition="condition"
            />
          </div>
      </div>

    </div>

    <div class="flex justify-end mt-4">
      <md-button @click="onBack" class="text-uppercase rounded">Prvious</md-button>
      <Button
        :loading="loading"
        class="bg-victoria rounded text-white mr-0"
        label="Next"
        type="button"
        @click="submit"
        >
      </Button>
    </div>
  </div>
</template>


<script>
import QuestionNumberInput  from '@/components/molecule/test-creation/QuestionNumberInput';
import  data  from '@/data/test-creation/data';
import { Button } from '@/components';
import { flatten } from 'lodash';
export default {
  components: {
    Button,
    QuestionNumberInput
  },
  data() {
    return {
      loading: false,
      items: data.SectionTypes,
      condition:false
    }
  },
  computed: {
    section() {
      return JSON.parse(localStorage.getItem('selectedSectionsUpdated'));
    },
    type() {
      return this.$route.query.type
    },
  },
 
  methods: {
    submit() {
      let data = [];
      let test = JSON.parse(localStorage.getItem('test'));

      Object.keys(this.section).map(item => {
        data.push(this.section[item]);
      });
      
      test.times = flatten(data).map(item => {
        return {
          question_type_id: item.id,
          preparation_time: item.preparation_time,
          answer_time: item.answer_time,
          is_beep: item.is_beep,
          quantity: item.quantity ? Number(item.quantity) : item.quantity,
          number_of_question: item.quantity ? Number(item.quantity) : item.quantity,
        }
      });
      
      // let excludes = ['id', 'created_by', 't_id', 'created_at'];

      // excludes.forEach(item => delete test[item])

      //will add test and selectedSectionsUpdated storage for data cach
      localStorage.setItem('test', JSON.stringify(test));
      localStorage.setItem('testQuestionCount', JSON.stringify(test));
      localStorage.setItem('selectedSectionsUpdated', JSON.stringify(this.section));

      this.$router.push({
        name: 'testCreation.createType',
        query: {type: this.type}
      });
    },
    onBack() {
      this.$router.push({
        name: 'testCreation.timeSet',
        query: {type: this.type}
      });
    },
  }
}
</script>