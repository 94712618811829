<template>
<div>
    <div class="flex align-center mb-3">
        <label class="w-16">{{ item && item.short_title }}</label>
        <InputFieldComponent v-model="quantity" class="w-12"/>
    </div>
  </div>
</template>

<script>
import { InputFieldComponent } from "@/components";

export default {
    components: {
        InputFieldComponent
    },
    props: {
        item: {
            type: Object
        },
    },
    data() {
        return {
        itemsType: localStorage.getItem("HandleItems")
        }
    },
    computed: {
        quantity: {
            get() {
                return this.item.quantity;
            },
            set(value) {
                this.item.quantity = value
            }
        }
    }
}
</script>