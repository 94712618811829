<template>
    <div>
        <div
            class="flex justify-center rounded border border-solid border-gray-400 py-2 px-4 pointer"
            :class="[classes]"
        >
                <div class="flex flex-col justify-center">
                <input
                    class="hidden"
                    accept="audio/*"
                    type="file"
                    ref="audioInput"
                    @change="setFile"
                />
                <div
                    style="align-self: center"
                    @click="$refs.audioInput.click()"
                    class=" inline-flex align-center rounded text-white pointer py-1"
                >

                    <svg
                        class="w-4"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        viewBox="0 0 26 26"
                        fill="#5B5B5B"
                    >
                        <path
                            d="M12.96875 0.3125C12.425781 0.3125 11.882813 0.867188 10.78125 1.96875L4.21875 8.5625C3.011719 9.773438 3.414063 10 4.6875 10L8 10L8 18C8 19.65625 9.34375 21 11 21L15 21C16.65625 21 18 19.65625 18 18L18 10L21.25 10C22.660156 10 22.957031 9.773438 21.75 8.5625L15.15625 1.96875C14.054688 0.867188 13.511719 0.3125 12.96875 0.3125 Z M 0 19L0 23C0 24.65625 1.34375 26 3 26L23 26C24.65625 26 26 24.65625 26 23L26 19L24 19L24 23C24 23.550781 23.550781 24 23 24L3 24C2.449219 24 2 23.550781 2 23L2 19Z"
                            fill="#fff"
                        />
                    </svg>
                    <div class="pl-3 text-left">
                    <p class="text-sm text-capitalize">Upload Audio</p>
                    </div>
                </div>
                <div v-if="src" class="align-center w-full">
                    <audio-player :src="src"/>
                </div>
                
                </div>

                <b class="text-danger" v-if="message">{{ message }}</b>
        </div>

        <span id="scrollTop" v-if="message"></span>
    </div>
  </template>
  
  <script>

  import { putObject} from "@/store/upload";
  
  const AWS = require('aws-sdk');
  const spacesBaseUrl = new AWS.Endpoint(process.env.VUE_APP_DIGITALOCEAN_SPACES_ENDPOINT);
  const urlMarge = spacesBaseUrl.protocol+'//'+process.env.VUE_APP_DIGITALOCEAN_SPACES_BUCKET+'.'+spacesBaseUrl.host
  const baseUrl =urlMarge+'/'+process.env.VUE_APP_DIGITALOCEAN_SPACES_FOLDER+'/'
       
export default {
    props: {
        fileUrl: {
            type: String
        },
        message: {
            type: String,
            default: () => null
        },
        classes: {
          type: String,
          default: ''
        }
    },
    data() {
        return {
            record: true,
            fileName: "",
            dataUrl: '',
            src: '',
            url:'',
            showUploadButton: false,
            file_path: "",
            path: 'pte-quiz'
        };
    },

    methods: {

        setFile(e) {
            //call file
            let CustomData = e.target.files[0];
            
            console.log("CustomData",CustomData)
            
            this.CustomData = URL.createObjectURL(CustomData)
            this.src = this.CustomData;
            this.file_url = this.src
            //path assign here
            const paths = this.path +'/audio/'+ CustomData.name 
            this.file_path = paths.toLowerCase();
            //filepath name
            //var filePath = CustomData.name 
            //filepath name replace . to 1 and remove wav 
            // var fileName = filePath.split('.').pop();
            // let path1 = filePath.replace(fileName, "1");
            // let path2 = path1.replace('.', "");
            // rename file name 
            // let file_paths = renameFile(this.file_path,path2);
            //  setTimeout(() => renameObject(this.file_path, file_paths),5000)
            // this.file_path = file_paths;
            // console.log("rr",file_paths)
            // put file hare
            this.filepath = this.path+'/audio'
            putObject(CustomData, this.filepath, "public-read")
            this.$emit('input',baseUrl+paths);
        },
    },
}
  </script>