<template>
    <div class="rules-container outline-gray-400 pb-6 rounded-md px-6 flex flex-col align-center h-full relative">
        <Tab 
			v-if="items.length"
            :items="items" 
			wrapperClass="c_simple_tab_two"
			@activeTab="onSetTab"
			titleKey="title"
            :activeTab="selectedTab"
        >
        </Tab>

        <div @click.prevent="createModule()" class="absolute right-20 top-20 text-algal font-semibold text-base pointer">
            <md-icon 
                class="text-algal text-lg font-light mx-1" 
            >
                visibility
            </md-icon>
            <span>Preview</span>
        </div>
		
        <section class="mt-8 w-100p flex flex-col justify-between" style="min-height: 50vh;">
            <RuleInformation v-if="selectedRule.rules" v-model="selectedRule" />

            <div class="flex align-center justify-end mt-6">
                <md-button 
                    class="px-5 py-2 h-12 text-base text-transform-none font-semibold text-gray-500 rounded-lg"
                    @click="goBack"
                >
                    Back
                </md-button>
                <Button 
                    :loading="loading"
                    label="Save & Next" 
                    type="submit" 
                    class="mr-0 px-5 py-2 h-12 bg-algal text-base font-semibold text-white rounded-lg"
                    @click.native="onSubmit()"
                >
                    Save & Next
                </Button>
            </div>
        </section>

        <Dialog :class="component">
            <components :content="content" :is="component"></components>
        </Dialog>

    </div>
</template>

<script>
import { Dialog } from "@/components";
import Tab from '@/components/atom/SimpleTab.vue';
import { SelectComponent, Button } from "@/components";
import { mapMutations, mapGetters, mapActions } from "vuex";
import PreviewRules from '@/components/molecule/online-class/rules/PreviewRules.vue';
import RuleInformation from "@/components/molecule/online-class/rules/RuleInformation.vue";

export default {
    name: 'Rules',

    components: {
        Tab,
        Dialog,
        PreviewRules,
        SelectComponent,
        Button,
        RuleInformation
    },

    data() {
        return {
            component: 'PreviewRules',
            content: {},
            selectedRule: {},
            items: [],
            allRules: [],
            loading: false,
            selectedTab: null,
        }
    },
    computed: {
		...mapGetters({
            getRules: "onlineClass/getRules",
			getClassInfo: "onlineClass/getClassInfo",
		}),
    },
    methods: {
        ...mapMutations({
            dialog: "setShowDialog",
            setRules: "onlineClass/setRules",
            setShowSnackbar: "setShowSnackbar",
        }),
        ...mapActions({
			onOnlineClassRulesCreation: "onlineClass/onOnlineClassRulesCreation",
            initialClassInfo:  "onlineClass/initialClassInfo",
		}),
        onSetTab(val) {
            if(this.allRules.length) {
                let selectedIndex = this.allRules.findIndex((rule) => val === rule.title);

                if(selectedIndex > -1) {
                    this.selectedRule = this.allRules[selectedIndex];

                    if(this.selectedRule) {
                        this.allRules[selectedIndex] = this.selectedRule;
                        this.setRules(this.allRules);
                    }
                }
            }
            this.selectedTab = val;

        },
        createModule() {
            this.component = 'PreviewRules';
            this.dialog(true);
        },
        goBack() {
            this.$router.push({
                name: 'onlineClassCreation.chapterDetails',
            });
        },
        onSubmit() {
            let totalModules = this.getRules.length;
            let selectedModuleIndex = this.getRules.findIndex((mo) => mo.id === this.selectedRule.id);


            if(selectedModuleIndex > -1 && totalModules > selectedModuleIndex + 1) {
                this.onSetTab(this.getRules[selectedModuleIndex + 1].title);
                // this.$emit('change-tab', this.getRules[selectedModuleIndex + 1].title);
                return
            } else {
                let index = this.getRules.findIndex((val) => val.title === this.selectedRule.title);
				if(index > -1 && this.selectedRule) {
					let cloneRules = JSON.parse(JSON.stringify(this.getRules));
					cloneRules[index] = this.selectedRule;
					this.setRules(cloneRules);
				}
            }

            let data = {
                id: this.getClassInfo.id,
                modules: [],
            };

            this.getRules.forEach((rule) => {
                let obj = {
                    module_id: rule.id,
                    rule: rule.rules.rule,
                    rule_value: rule.rules.rule_value
                }

                data.modules.push(obj);
            });

            this.loading = true;

            this.onOnlineClassRulesCreation(data)
            .then((response) => {
                this.$router.push({
                    name: 'onlineClassCreation.review'
                });
                
                localStorage.setItem('classBasicInfo', JSON.stringify(response.data));
				this.setShowSnackbar('Rules Added Successfully');
			})
			.catch((error) => {
				console.log("error", error);
				this.setShowSnackbar(error);
			})
			.finally(() => this.loading = false);
		},
    },
    created() {
        this.initialClassInfo();
        this.items = JSON.parse(JSON.stringify(this.getRules));

        let modules = JSON.parse(JSON.stringify(this.getRules));

        if(modules && modules.length > 0) {
            modules.forEach(moduleObj => {
                if(moduleObj.rules) {
                    moduleObj.rules = {
                        "rule": moduleObj.rules.rule,
                        "rule_value": moduleObj.rules.rule_value,
                    }
                } else {
                    moduleObj.rules = {
                        "rule": "no_rule",
                        "rule_value": "",
                    };
                }
            });
        }

        this.allRules = JSON.parse(JSON.stringify(modules));
	},

    watch: {
        selectedRule:{ 
            handler: function(val) {
                if(val.rule === 'no_rule') {
                    this.selectedRule.rule_value = "";
                } else if(val.rule === 'publish_after' && !val.rule_value) {
                    this.selectedRule.rule_value = "1";
                }
            },
            deep: true
        }
    }
}
</script>

<style lang="scss">

.rules-container {
    .c-radio_btn {
        .md-radio-container {
            border: 1px solid #661dd0 !important;
            
        }

        .md-radio-container::after {
            background-color: #661dd0 !important;
        }
    }

    .tab-item-active {
        font-weight: bold;
        color: #0000 !important;
        border-bottom: 2px solid #661dd0 !important;
        margin-bottom: -2px;
    }
}
</style>