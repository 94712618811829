<template>
  <div class="outline-gray-400 rounded-md p-6 flex flex-col justify-between h-full test-time-set">
    <h2 class="text-uppercase mb-6">Time set</h2>
    
    <div class="md-layout md-gutter quiz_set">
      <div v-for="(item, index) in section" :key="index" class="md-layout-item md-xlarge-size-35 md-large-size-35 md-medium-size-35 md-small-size-50 md-xsmall-size-100">
        <TimeSection :label="item.quiz_type_title" type="quiz" class="md-gg-pte">
          <TestTimeSet 
            :item="item"
            type="quiz"
            :isShowPreparationTime="false"
          />
        </TimeSection>
      </div>
    </div>
    <div class="flex justify-end mt-4">
      <md-button @click="onBack" class="text-uppercase rounded">Previous</md-button>
      <Button
        :loading="loading"
        class="bg-victoria rounded text-white mr-0"
        label="Next"
        type="button"
        @click="submit"
        >
      </Button>
    </div>
  </div>
</template>

<script>
import TestTimeSet from '@/components/molecule/test-creation/TestTimeSet';
import TimeSection from '@/components/molecule/test-creation/TimeSection';
import { Button } from '@/components'
export default {
  components: {
    Button,
    TestTimeSet,
    TimeSection
  },
  data() {
    return {
      loading: false,
      answerTime: '00:00',
      boolean: false,
      quiz: [ 
        { id: "1", slug: "text_text", title: "text+text",answer_time: 0,preparation_time: 0}
      ],
    }
  },
  computed: {
    section() {
      let exist = localStorage.getItem('selectedSectionsUpdated');
      if(!exist) {
        let sections = JSON.parse(localStorage.getItem('selectedSections'));
        sections.forEach(item => {
          return item
        });
        return sections;
      }
      return JSON.parse(exist);
    },
    type() {
      return this.$route.query.type
    },
  },
  mounted(){
    console.log('ss',this.section)
  },
  methods: {
    async submit() {
      this.loading = false;
      localStorage.setItem('selectedSectionsUpdated', JSON.stringify(this.section));
       this.$router.push({
          name: 'quizCreation.numberOfQuestion',
          query: {type: this.type}
        });
    },

    onBack() {
      this.$router.push({
        name: this.type === 'quiz-test' ? 'quizCreation.testInformation' : 'testCreation.testInformation',
        query: {type: this.type}
      });
    }
  }
};
</script>