<template>
    <div class="md-layout flex flex-col m-5 p-2">
        <div @click="dialog(false)" class="back-button cursor mb-5">
            <md-icon class="md-icon md-icon-font text-blue text-4xl md-theme-default">arrow_back</md-icon>
        </div>

        <div class="text-lg font-bold ml-3">Preview Rules</div>

        <div class="md-layout-item ml-3 mt-5" v-for="(item, i) in rules" :key="i">
            <div class="md-layout flex flex-col justify-center align-start">

                <span class="text-base font-semibold my-2">{{ item.title }}</span>
    
                <div class="md-layout-item my-1 w-100p"  v-for="(ch, index) in item.chapters" :key="index">
                    <span class="text-algal mr-1">
                        {{ ch.title }}:
                    </span>
                    <span class="ml-4" v-html="ch.description"></span>
                </div>

                <div class="md-layout-item bg-lite-ash rounded-sm mt-2 py-1 px-3 text-xs rounded text-center"> {{ rule(item.rules) }}</div>
                
                <md-divider class="my-2" ></md-divider>
            </div>
        </div>
    </div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";

export default {
    name: 'PreviewRules',

    data() {
        return {
            rules: []
        }
    },

    computed: {
		...mapGetters({
            getRules: "onlineClass/getRules",
		}),
    },
    methods: {
		...mapMutations({
			dialog: "setShowDialog",
		}),
        rule(rules) {
            if(rules.rule === 'no_rule') {
                return 'No Rules'
            } else if(rules.rule === 'publish_after') {
                return `Publish after ${rules.rule_value} hours of the opening of previous chapter`
            }
        }
	},
    created() {
        this.rules = this.getRules;
	},
}
</script>
