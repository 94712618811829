<template>
  <div class="outline-gray-400 rounded-md p-6 flex flex-col justify-between h-full">
        <InputFieldComponent
            label="Quiz Name"
            label-class="text-base"
            placeholder="SPLECIAL QUIZ TEST"
            v-model.trim="form.title"
            class="w-50p"
          />
          <div class="mt-6" v-if="type == 'quiz-test'">
            <label class="text-uppercase font-bold block text-base">Choose Template</label>
            <RadioButtonComponent
              label-class="text-lg text-uppercase"
              :items="[
                {id:'ONE_BY_ONE', label: 'One by One'},
                {id:'ALL_IN_ONE', label: 'All in One'},
              ]"
              v-model.trim="form.template_type"
            />
          </div>
          <div v-if=" type == 'quiz-test'">
              <TextAreaComponent
                label="Start Message"
                label-class="text-base"
                v-model.trim="form.start_message"
              />
              <TextAreaComponent
                label="End Message"
                label-class="text-base"
                v-model.trim="form.end_message"
              />
          </div>
        <TextAreaComponent
          label="Short Note"
          label-class="text-base"
          v-model.trim="form.note"
        />

        <div class="flex justify-end mt-4">
          <md-button @click="formClear" class="text-uppercase">Clear all</md-button>
          <Button
            :loading="loading"
            class="bg-victoria rounded text-white mr-0"
            label="Next"
            type="button"
            @click="submit"
            >
          </Button>
        </div>
  </div>
</template>

<script>
import {
  Button,
  InputFieldComponent,
  TextAreaComponent,
  RadioButtonComponent,
  } from "@/components"
import { mapActions, mapMutations, mapGetters } from 'vuex';
export default {
  name: "TestInformationView",
  components: {
    Button,
    RadioButtonComponent,
    InputFieldComponent,
    TextAreaComponent
  },
  data() {
    return {
      form: {
        start_message: '',
        end_message: '',
        note: '',
        organization_id: localStorage.getItem('organization_id'),
        template_type: "ONE_BY_ONE",
        title: '',
        create_type: "MANUAL",
        active: 0,
        times: [
        {
            quiz_type_id : '3e98b16e-cb0c-478d-86b4-4a5e847f635d',
            preparation_time : 40,
            answer_time : 5,
            is_beep : true,
            quantity : 0,
            type: "Video+Text",
            short_title: "Video+Text"
        },
        {
            quiz_type_id : '6a605ef1-f0fa-4b03-bd7c-b3f26ae6f54c',
            preparation_time : 40,
            answer_time : 5,
            is_beep : true,
            quantity : 0,
            type: "Audio+Text"
        },
        {
            quiz_type_id : 'b98962ee-9f88-41c5-89e6-78ea12982062',
            preparation_time : 40,
            answer_time : 5,
            is_beep : true,
            quantity : 0,
            type: "Image+Text"
        },
        {
            quiz_type_id : 'd93aec25-a7c5-4a02-ac70-263b95dc8662',
            preparation_time : 40,
            answer_time : 5,
            is_beep : true,
            quantity : 0,
            type: "Text+Text"
        }
    ],
      },
      section: '',
      // questions_type: '',  
      loading: false,
      handleSection: '',
    }
  },
  computed: {
    ...mapGetters({
      quizList: "question/getQuiz",
      getQuestionTypes: "question/getQuestionTypes"
    }),
    type() {
      return this.$route.query.type
    },
    items() {
      return this.getQuestionTypes.map(item => {
        item.label = item.name;
        return item;
      })
    },
    title() {
      //const x = JSON.parse(localStorage.getItem('SlectQuizType'));
      return 'asaas';
    },
  },
  watch: {
    section(value) {
        if(this.type == 'pte-practice') {
            this.actGetQuestionTypes(`?section=${value}`);
        }
        this.form.sections = [
            {section: value}
        ]
        //this.$root.handleSection = this.form.sections[0].section;
       //console.log('ssdd',this.actGetQuestionTypes(`?section=${value}`))
    }
  },

  methods: {
    ...mapMutations({
      setShowSnackbar: "setShowSnackbar"
    }),
    ...mapActions({
      actTestCreationSave: "quizCreation/actTestCreationSave",
      actGetQuiz: "question/actGetQuiz",
      actTestStepUpdate: "quizCreation/actTestStepUpdate",

    }),
    submit() {
      this.loading = true;
      delete this.form.section;
      let test = JSON.parse(localStorage.getItem('test'));
      if(test){
        this.UpdateData();
      } else {
        
        this.actTestCreationSave(this.form)
        .then(({sections, quiz_set, message}) => {
            this.$router.push({
              name: 'quizCreation.timeSet',
              query: {type: this.type}
            });
            console.log('xx',this.type,sections, quiz_set, message)
          // }
          this.setShowSnackbar(message);
          localStorage.setItem('test', JSON.stringify(quiz_set));
          localStorage.setItem('selectedSections', JSON.stringify(quiz_set.quiz_times));
          localStorage.removeItem('selectedSectionsUpdated');
        }).catch(error => {
            console.log(error);
            
        }).finally(() => this.loading = false);
      }
    },
    async UpdateData() {
      this.loading = true;
        let test = JSON.parse(localStorage.getItem('test'));
        let response = await this.actTestStepUpdate({
          testId: test.id,
          payload: this.form
        });
        if(response){
          let responseData = response.quiz_set;
          if(!responseData.times) responseData.times = [];
          responseData.times = responseData.quiz_times;
          localStorage.setItem('test',JSON.stringify(responseData));
          localStorage.setItem('quizQuestionCount',JSON.stringify(responseData));
          this.$router.push({
            name: 'quizCreation.timeSet',
            query: {type: this.type}
          });
          this.loading = false;
        }
        
    },
    formClear() {
      let kyes = ['title', 'start_message', 'end_message', 'note'];
      kyes.forEach(key => this.form[key] = '');
      this.form.type = 'FULL_MOCK'
    }
  },
  created() {
    this.actGetQuiz(``);
    const data = JSON.parse(localStorage.getItem('test'));
    if(data) {
      this.form.title = data.title;
      this.form.template_type = data.template_type;
      this.form.start_message = data.start_message;
      this.form.end_message = data.end_message;
      this.form.note = data.note;
      this.form.times = data.times;
    }
  }
}
</script>