<template>
  <div id="quiz_test" class="md-sl__slide">
    <div class="md-layout-column md-sl__br">
      <div v-for="(quiz,index) in question.quizzes" :key="quiz.id" v-show="current_slide_number === index" :class="{'is-active-slide': current_slide_number === index}">
        <div class="rounded-md p-4 pb-0 flex flex-col justify-between h-full">
          <div class="text-uppercase  bg-link-water p-6 rounded flex justify-between align-center">
            <div class="flex align-center">
              <h2 class="text-lg font-bold text-white bg-primary px-2 py-2 rounded-sm">{{ quiz.quiz_type_title }}</h2>
              <p class="text-gray-600 text-lg font-bold">
                <span class="text-primary pr-2 pl-2 ml-2 py-1 md-pte-back_prev">{{ quiz.answer_type }}</span> 
              </p>
            </div>
            <div class="flex align-center">
              <p class="text-gray-600 text-lg font-bold">
                <span class="text-primary pr-2 pl-2 ml-2 py-1 md-pte-back_prev">{{ index+1 }} of {{ question.quizzes.length }}</span> 
              </p>
            </div>
          </div>
        </div>
        <components ref="perent" :is="convertor(quiz.quiz_type_title)" :quiz="quiz"></components>
        <components ref="child" :is="convertors(quiz.answer_type)" :quiz="quiz"></components>
        <div class="flex justify-center mt-16">
          <md-button @click="onPrevious(index)" class="prev rounded text-white my-0 ml-0" :class="current_slide_number!== 0 ? 'bg-victoria': 'bg-periwinkle-gray'">
          <md-icon class="text-white m-0">keyboard_arrow_left</md-icon> Prev</md-button>
          <md-button @click="onNext(index)" class="next rounded text-white my-0 mr-0" :class="question.quizzes.length-1 !== current_slide_number ? 'bg-victoria': 'bg-periwinkle-gray'">Next
            <md-icon class="text-white m-0">keyboard_arrow_right</md-icon></md-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ArrowIcon from '@/assets/svg/arrowIcon.svg';
import Questions from "@/views/student/quiz-test/item";
  export default {
    name: 'DialogCustom',
    components:{
      ArrowIcon,
      ...Questions
    },
    props: {
      question: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
          items:[],
          current_slide_number: 0,
        }
    },
  computed: {
    
  },
  methods: {
    convertor(title){
      if( title === 'Audio+Image'){
        return 'AudioAndText'; 
      } 
      if(title !== 'AudioAndImage'){
        return title.replace('+', "And"); 
      }
    },
    convertors(title){
      if(title === 'MCQ-CHOOSE-MULTIPLE-ANSWER'){
        return 'MCQChooseSingleAnswer'; 
      }
      if(title === 'MCQ-CHOOSE-SINGLE-ANSWER'){
        return 'MCQChooseSingleAnswer'; 
      } 
      if( title === 'TEXT'){
        return 'TextAnswer'
      }
    },
    onNext(index) {
      if(this.question.quizzes.length-1!=this.current_slide_number){
        this.current_slide_number++;
        console.log(index+1)
      } else {
        this.current_slide_number;
      }
    },
    onPrevious(index) {
      if(this.current_slide_number!=0){
        this.current_slide_number--;
        console.log(index-1)
      } else {
        this.current_slide_number;
      }
    },
  },
};
</script>
