<template>
    <div class="chapter-parts-row-item">
        <div class="md-layout-item flex justify-between align-center mb-1">
            <div class="flex">
                <h2 class="text-base mb-4 font-semibold text-capitalize">{{ type }} Instruction </h2>
            </div>
            
            <div class="flex align-center">
                <Button 
                    v-if="isEditable"
                    label="Save" 
                    type="submit" 
                    class="px-1 py-1 bg-algal text-sm font-semibold text-white rounded-lg"
                    @click.native="isEditable = false"
                >
                    Save
                </Button>

                <md-icon 
                    class="p-1 text-danger text-2xl mr-3" 
                    style="cursor: pointer;"
                    @click.native="$emit('on-row-delete', index)"
                >
                    delete
                </md-icon>

                <md-icon  v-if="!isEditable" class="text-sm font-bold pointer" @click.native="isEditable = true">border_color</md-icon>
            </div>
        </div>

        <TaskInstruction v-if="type == 'task'" v-model="value" :isEditable="isEditable" />
        <ButtonInstruction v-if="type == 'button'" v-model="value" :isEditable="isEditable" />
        <MaterialInstruction v-if="type == 'material'" v-model="value" :isEditable="isEditable" />

        <md-divider class="my-6"></md-divider>
    </div>
</template>

<script>
import TaskInstruction from '@/components/molecule/online-class/chapter-details/TaskInstruction.vue';
import ButtonInstruction from '@/components/molecule/online-class/chapter-details/ButtonInstruction.vue';
import MaterialInstruction from '@/components/molecule/online-class/chapter-details/MaterialInstruction.vue';
import { Button } from "@/components";

export default {
    name: 'PartRowItem',
    
    components: {
        TaskInstruction,
        ButtonInstruction,
        MaterialInstruction,
        Button
    },

    props: {
        value: {
            type: Object,
            defaul: null,
        },
        type: {
            type: String,
            defaul: '',
        },
        index: {
            type: Number,
            default: -1,
        },
        isLastItem: {
            type: Boolean,
            default: false,
        },
        isOnlyItem: {
            type: Boolean,
            default: true,
        },
    },

    data(){
        return {
            isEditable: true,
        }
    },

    computed: {
        instructionType() {
            if(this.type) {
                return this.type.toUpperCase()[0] + this.type.slice(0,1).toLowerCase();
            }

            return '';
        }
    },
}
</script>