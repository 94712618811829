<template>
  <div class="outline-gray-400 rounded-md p-6 flex flex-col h-full">
    <ul class="flex justify-center" style="border-bottom: 1px solid #cbd5e0">
      <div v-for="(section, index) in sections" :key="index">      
        <template> 
          <li :class="isActive === index ? 'active_nav' : ''" class="flex px-2 pointer mx-2 text-uppercase text-base pb-3 font-semibold" @click="getSectionWiseQuestions(index, section.name)">
            {{ section.name }} 
            <span class="ml-1 px-1 rounded-lg" style="background: #e9e9e9; color: #6d6d6d">{{ section.total }}</span>
          </li>
        </template>
      </div>
    </ul>
    <div class="mt-6" v-if="items.length > 0">
      <div class="bg-lite-ash rounded-md my-3" v-for="(item, index) in items" :key="index">
        <template>
          <h3 class="pt-4 px-4 text-victoria text-base text-capitalize mb-2">{{ item.answer_type }}</h3>
          <ul class="pb-2 px-4">
            <li
              class="text-base text-capitalize mb-2 mt-5"
            >{{index+1}}. {{ item.index }}</li>
          </ul>
        </template>
      </div>
    </div>
    <div v-else class="mt-6">
      <div class="bg-lite-ash rounded-md my-3">
        <p class="text-center p-6">No data</p>
      </div>
    </div>
    <div class="flex justify-between mt-4 basis align-end">
      <div>
        <md-button v-if="testType == 'MANUAL'" class="rounded outline-gray-400">{{ totalQuestion }} Question</md-button>
        <md-button v-if="testType == 'RANDOM'" @click="regenerate" class="rounded outline-gray-400">
        <md-icon>refresh</md-icon> Regenerate</md-button>
      </div>
      <div>
        <!-- <md-button class="text-uppercase rounded">Clear all</md-button> -->

        <md-button @click="onBack" class="text-uppercase rounded">Previous</md-button>

        <router-link :to="{name: 'quizCreation.testPreview', query: {type: type}}">
          <md-button type="submit" class="text-uppercase mr-0 bg-victoria text-white rounded">Next</md-button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { sumBy } from 'lodash'
export default {
  data() {
    return {
      isActive: 0,
      sections: [],
      response: []
    }
  },
  computed: {
    test() {
      return JSON.parse(localStorage.getItem('test'));
    },
    testType() {
      return localStorage.getItem('testType');
    },
    totalQuestion() {
      return sumBy(this.sections, item => item.total)
    },
    items() {
      let data = this.response[this.isActive];
      return data.quizzes
    },
    type() {
      return this.$route.query.type
    },
  },
  methods: {
    ...mapActions({
      actTestPreview: "quizCreation/actQuizPreview",
      actTestStepUpdate: "quizCreation/actTestStepUpdate"
    }),
    getSectionWiseQuestions(index = 0) {
       this.isActive = index;
    },
    getQuestions() {
      this.actTestPreview({
        quiz_set_id: this.test.id,
      }).then(response => {
        this.sections = response.map(item => {
          return {
            name: item.type,
            total: item.total,
          }
        });
        this.response = response;
        console.log("test",this.item)
      });
    },
    onBack() {
      this.$router.push({
        name: this.type === 'quiz-test' ? 'quizCreation.chooseQuestion' : 'testCreation.chooseQuestion',
        query: {type: this.type}
      });
    },
    async regenerate() {
      let test = JSON.parse(localStorage.getItem('test'));
        let testData = JSON.parse(localStorage.getItem('testQuestionCount'));
        testData.create_type = 'RANDOM';
        let response = await this.actTestStepUpdate({
          testId: test.id,
          payload: testData
        });
        localStorage.setItem('createTypeResponse',JSON.stringify(response));
        this.getQuestions();
    }
  },
  mounted() {
    this.getQuestions() 
  }
}
</script>