<template>
    <div class="button-instruction flex flex-col justify-between h-full">
        <TextAreaComponent
            v-if="isEditable"
            v-model.trim="value.instructions"
            class=""
            :textAreaClass="'rounded-lg w-full italic text-sm'"
            :rows="6"
        />

        <p v-else class="text-sm">{{ value.instructions }}</p>

        <div class="md-layout-item mt-6" v-if="isEditable">
            <components ref="perent" :is="component" v-model="value"></components>
        </div>

        <div class="md-layout-item mt-6" v-else>
            <div class="flex" v-if="value.tasks">
                <ImageAndContents 
                    class="w-48p mr-1" 
                    v-for="(item, i) in value.tasks" 
                    :key="i"  
                    :item="item" :type="''" 
                />
            </div>
        </div>
    </div>
</template>

<script>
import { SelectComponent, TextAreaComponent } from "@/components";
import TaskTable from "@/components/molecule/online-class/chapter-details/TaskTable.vue"
import ImageAndContents from "@/components/molecule/online-class/chapter-details/ImageAndContent.vue";

export default {
    name: 'MaterialInstruction',

    components: {
        SelectComponent,
        TextAreaComponent,
        TaskTable,
        ImageAndContents
    },

    props: {
        isEditable: {
            type: Boolean,
            default: false
        },
        value: {
            type: Object,
            default: null,
        }
    },

    data() {
        return {
            component: 'TaskTable',
            contentItems: [
                {name: 'ImageAndContents', title: 'Population Of Canada', type: 'docs'},
                {name: 'ImageAndContents', title: 'Global Warming', type: 'docs'}
            ]
        }
    },
}
</script>