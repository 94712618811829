<template>
    <div class="border rounded-lg border-solid border-gray-400 px-6 py-4 my-4">
        <div class="flex align-center">
            <div class="mt-1 w-75p">
                <div>
                    <h2 class="text-base text-algal">{{ item.title }}</h2>
                </div>
            </div>

            <div class="cursor w-25p flex justify-end">
                <p class="text-sm font-medium font-inter">
                    <md-icon class=" text-blue text-sm">
                        timelapse
                    </md-icon><span style="font-size: 14px;">{{senitizeDurationToHour(item?.duration)}} Hours</span> 
                    
                    <md-icon class=" text-blue text-sm md-theme-default">
                        assignment_turned_in
                    </md-icon><span style="font-size: 14px;">Tasks</span>
                </p>
            </div>
        </div>

        <div class="mt-2 w-100p">
            <p class="mt-3 text-sm text-capitalize line-height-27" v-html="item?.description"></p>
        </div>
    </div>
</template>

<script>
import { secondToHour } from '@/utils';
export default {
  props: {
    item: {
        type: Object,
        default: null,
    },
  },
  methods: {
    senitizeDurationToHour(duration) {
        return secondToHour(duration);
    }
  }
}
</script>