<template>
    <div class="chapters_details outline-gray-400 rounded-md py-6 px-6 align-center h-full relative">
       
        <div class="flex align-center">
            <span class="text-2xl font-semibold mr-3">{{ getSelectedChapterModule.title }}</span>
            <span class="text-xs bg-light-blue text-sky px-2 py-1 rounded">{{ getSelectedChapter.title }}</span>
        </div>

        <form @submit.prevent="onSubmit(true)" class="mt-8">
          
            <section>
                <InputFieldComponent
                    label="Name Of The Chapter"
                    label-class="text-base mb-4 font-semibold text-capitalize"
                    placeholder="Write Here.."
                    v-model.trim="form.title"
                    :classes="['border', 'border-solid', 'rounded-lg', 'border-gray-400']"
                    class="w-50p"
                    :inputClass="'p-4 font-semibold h-10 text-sm'"
                />

                <div class=" my-8">

                    <label
                        class="mb-3 text-base mb-4 font-semibold text-capitalize block"
                    >
                        Duration
                    </label>

                    <div class="flex">
                        <InputFieldComponent
                            label=""
                            label-class="text-base mb-4 font-semibold text-capitalize border-gray-400"
                            placeholder="hh"
                            v-model.trim="form.hour"
                            :classes="['border', 'border-solid', 'rounded-lg', 'text-center', 'border-gray-400']"
                            class="w-20"
                            :inputClass="'p-2 font-semibold h-10 text-sm text-italic text-gray-800'"
                        />
                        <InputFieldComponent
                            label=""
                            label-class="text-base mb-4 font-semibold text-capitalize"
                            placeholder="mm"
                            v-model.trim="form.minute"
                            :classes="['border', 'border-solid', 'rounded-lg', 'text-center', 'border-gray-400']"
                            class="w-20 mx-3"
                            :inputClass="'p-2 font-semibold h-10 text-sm text-italic text-gray-800'"
                        />
                        <InputFieldComponent
                            label=""
                            label-class="text-base mb-4 font-semibold text-capitalize"
                            placeholder="ss"
                            v-model.trim="form.second"
                            :classes="['border', 'border-solid', 'rounded-lg', 'text-center', 'border-gray-400']"
                            class="w-20"
                            :inputClass="'p-2 font-semibold h-10 text-sm text-italic text-gray-800'"
                        />
                    </div>
                </div>

                <div class="mt-6 class-h-200">
                    <TextEditor
                        v-model="form.description"
                        class="text-editor-rounded "
                        label="Chapter Description"
                        labelClass="text-base mb-4 font-semibold text-capitalize"
                    />
                </div>
            </section>

            <section v-if="form.parts && form.parts.length">
                <Parts 
                    v-for="(part, index) in form.parts" 
                    v-model="form.parts[index]"
                    :key="index"
                    :index="index"
                    :is-last-item="index == form.parts.length - 1"
                    :is-only-item="form.parts.length == 1"
                    @on-row-add="onRowAdd"
                    @on-row-delete="onRowDelete"
                />

            </section>
            
            <section v-else class="flex justify-center align-center py-10">
                <Button 
                    label="Add a part" 
                    type="submit" 
                    @click.native="onRowAdd"
                    class="mr-0 px-5 py-2 h-12 bg-algal text-base font-semibold text-white rounded-lg"
                >
                    Add Part
                </Button>
            </section>
            

            <div class="flex align-center justify-end mt-6">
                <md-button 
                    class="px-5 py-2 h-12 text-base text-transform-none font-semibold text-gray-500 rounded-lg"
                    @click="onGoBack"
                >
                    Back
                </md-button>
                <Button 
                    label="Save & Next" 
                    type="submit" 
                    :loading="loading"
                    class="mr-0 px-5 py-2 h-12 bg-algal text-base font-semibold text-white rounded-lg"
                >
                    Save & Next
                </Button>
            </div>
        </form>
    </div>
</template>

<script>
import { InputFieldComponent, Button } from "@/components";
import TextEditor from "@/components/atom/form/TextEditor";
import Parts from "@/components/molecule/online-class/chapter-details/Parts.vue";
import { mapGetters, mapMutations, mapActions } from "vuex";
import { normalTimeToSecond, secondToSplitTime } from '@/utils';

export default {
    name: 'ChapterDetails',
    components: {
        Button,
        InputFieldComponent,
        TextEditor,
        Parts,
    },

    data() {
        return {
            form: {
                title: 'English Is Very Important',
                hour: '00',
                minute: '00',
                second: '00',
                description: '',
                duration: '00',
                parts: []
            },
            loading: false,
        }
    },
    computed: {
		...mapGetters({
			getClassInfo: "onlineClass/getClassInfo",
			getModules: "onlineClass/getModules",
			getSelectedChapter: "onlineClass/getSelectedChapter",
			getSelectedChapterModule: "onlineClass/getSelectedChapterModule",
		}),
    },
    methods: {
        ...mapMutations({
            setShowSnackbar: "setShowSnackbar",
			setSelectedChapter: "onlineClass/setSelectedChapter",
			setSelectedChapterModule: "onlineClass/setSelectedChapterModule",
		}),
        ...mapActions({
			onOnlineClassChapterCreation: "onlineClass/onOnlineClassChapterCreation",
			initialClassInfo: "onlineClass/initialClassInfo",
		}),
        onRowAdd() {
            const clone = JSON.parse(JSON.stringify(this.form.parts));

            clone.push({
                title: 'Title of the part ' + (clone.length + 1),
                sections: [],
            });

            this.form.parts = clone;
        },

        onRowDelete(i) {
            if (i > -1) {
                const clone = JSON.parse(JSON.stringify(this.form.parts));

                if (clone.length > 1) {
                    clone.splice(i, 1);
                }

                this.form.parts = clone;
            }
        },
        onSetData() {
            let cloneData = JSON.parse(JSON.stringify(this.getSelectedChapter));

            this.form.title = cloneData.title;
            this.form.description = cloneData.description ? cloneData.description : '';
            this.form.parts = cloneData.parts;

            if(cloneData.duration) {
                this.form.hour = secondToSplitTime(cloneData.duration).split(":")[0]
                this.form.minute = secondToSplitTime(cloneData.duration).split(":")[1]
                this.form.second = secondToSplitTime(cloneData.duration).split(":")[2]
            } else {
                this.form.hour = "00";
                this.form.minute = "00";
                this.form.second = "00";
            }
        },
        onGoBack(){
            let totalModules = this.getModules.length;
            let currentModuleIndex = this.getModules.findIndex((item) => item.title === this.getSelectedChapterModule.title);
            let totalChaptersOnThisCurrentModule = this.getModules[currentModuleIndex].chapters.length;
            let currentChapterIndex = this.getModules[currentModuleIndex].chapters.findIndex((chapter) => chapter.title === this.getSelectedChapter.title);

            if(currentModuleIndex == 0 && currentChapterIndex == 0) {
                this.$router.push({
                    name: 'onlineClassCreation.moduleInformation'
                })
            } else if(totalModules > currentModuleIndex && totalChaptersOnThisCurrentModule == currentChapterIndex + 1) {
                this.setSelectedChapterModule(this.getModules[currentModuleIndex]);
                this.setSelectedChapter(this.getModules[currentModuleIndex].chapters[currentChapterIndex - 1]);
            }  else if(totalModules > currentModuleIndex && totalChaptersOnThisCurrentModule > currentChapterIndex) {
                this.setSelectedChapterModule(this.getModules[currentModuleIndex - 1]);
                let currentModuleChapterNumber = this.getSelectedChapterModule.chapters.length;
                this.setSelectedChapter(this.getModules[currentModuleIndex - 1].chapters[currentModuleChapterNumber - 1]);

            } else if(totalModules  ==  currentModuleIndex + 1 && currentChapterIndex == 0) {
                this.setSelectedChapterModule(this.getModules[currentModuleIndex - 1]);
                let currentModuleChapterNumber = this.getSelectedChapterModule.chapters.length;
                this.setSelectedChapter(this.getModules[currentModuleIndex - 1].chapters[currentModuleChapterNumber - 1]);
            }

            this.onSetData();
        },
        onSubNext() {
            let totalModules = this.getModules.length;
            let currentModuleIndex = this.getModules.findIndex((item) => item.title === this.getSelectedChapterModule.title);
            let totalChaptersOnThisCurrentModule = this.getModules[currentModuleIndex].chapters.length;
            let currentChapterIndex = this.getModules[currentModuleIndex].chapters.findIndex((chapter) => chapter.title === this.getSelectedChapter.title);

            if(totalModules > currentModuleIndex + 1 && totalChaptersOnThisCurrentModule > currentChapterIndex + 1) {
                this.setSelectedChapterModule(this.getModules[currentModuleIndex]);
                this.setSelectedChapter(this.getModules[currentModuleIndex].chapters[currentChapterIndex + 1]);
            } else if(totalModules > currentModuleIndex + 1) {
                this.setSelectedChapterModule(this.getModules[currentModuleIndex + 1]);
                this.setSelectedChapter(this.getModules[currentModuleIndex + 1].chapters[0]);
            } else if(totalModules >= currentModuleIndex + 1 && totalChaptersOnThisCurrentModule > currentChapterIndex + 1) {
                this.setSelectedChapter(this.getModules[currentModuleIndex].chapters[currentChapterIndex + 1]);
            } else {
                this.$router.push({
                    name: 'onlineClassCreation.rules'
                })
            }

            this.onSetData();
        },
        onSubmit() {

            this.loading = true;

            let time = `${this.form.hour}:${this.form.minute}:${this.form.second}`;
            let second = normalTimeToSecond(time);

            let data = {
                id: this.getClassInfo.id,
                chapter_id: this.getSelectedChapter && this.getSelectedChapter.id ? this.getSelectedChapter.id : '',
                title: this.form.title,
                duration: second,
                description: this.form.description,
                parts: [],
            };

            if(this.form.parts.length) {
                this.form.parts.forEach((part) => {
                    let obj = {
                        id: part.id ? part.id : '',
                        title: part.title,
                        sections: part.sections,
                    };

                    data.parts.push(obj);
                });
            }

            this.onOnlineClassChapterCreation(data)
            .then((response) => {
                localStorage.setItem('classBasicInfo', JSON.stringify(response.data));
                this.onSubNext();
				this.setShowSnackbar(response.message);
			})
			.catch((error) => {
				console.log("error", error);
				this.setShowSnackbar(error?.message);
			})
			.finally(() => this.loading = false);
		},
    },
    created() {
        this.initialClassInfo();
        this.setSelectedChapterModule(this.getModules[0]);
        this.setSelectedChapter(this.getModules[0].chapters[0]);
        this.onSetData();
    }

}
</script>

<style lang="scss">
.chapters_details {
    .ck-editor__editable {
        height: 150px !important;
    }
}
</style>