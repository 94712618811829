<template>
    <div class="wrapper">
        <ul class="StepProgress">
            <ChapterRowItem 
                v-for="(item, i) in value" 
                :key="item.lastIndex"
                v-model="value[i]"
                :index="i"
                :is-last-item="i == value.length - 1"
                :is-only-item="value.length == 1"
                @on-row-add="onRowAdd"
                @on-row-delete="onRowDelete"
                ref="chapterRowItem"
            />
        </ul>
    </div>
</template>

<script>
import ChapterRowItem from "@/components/molecule/online-class/chapter/ChapterRowItem.vue";
import { mapMutations, mapGetters } from "vuex";

export default {
    name: 'ChapterList',
    
    components: {
        ChapterRowItem,
    },

    props: {
        value: {
            type: Array,
            required: true,
        },
        module: {
            type: Object,
            default: null,
        }
    },

    computed: {
		...mapGetters({
			getModules: "onlineClass/getModules",
			getModule: "onlineClass/getModule",
		}),
    },
    methods: {
        ...mapMutations({
			dialog: "setShowDialog",
			setModule: "onlineClass/setModule",
		}),
        onRowAdd() {
            this.$emit('on-row-add');
        },

        onRowDelete(i) {
            this.$emit('on-row-delete', i)
        },
        onValidate() {
            this.$refs.chapterRowItem.forEach((item, index) => {
                this.$refs.chapterRowItem[index].onValidate();
            })
        }
    }
}
</script>
