<template>
  <div class="mb-8">
    <h2 class="text-victoria text-base text-uppercase">{{ label }}</h2>
    <table class="test-time">
      <thead>
        <tr>
          <th></th>
          <th v-if="type != 'quiz'">PRE.TIME</th>
          <th>ANS.TIME</th>
          <th v-if="type != 'quiz'">BEEP</th>
        </tr>
      </thead>
      <tbody>
        <slot></slot>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
    props: {
        label: {
            type: String
        },
        type:{
          type: String
        }
    }
}
</script>