<template>
    <div class="outline-gray-400 rounded-md px-6 flex flex-col align-center h-full">
        <Tab 
            :items="items" 
            :classes="'border-none'" 
            :activeClass="'tab-item-active'"
            style="width: 100%; text-align: center;" 
            class="border-b border-b-solid border-gray-300"
        >
        </Tab>

        <section class="pb-6 w-100p">
            <router-view></router-view>
        </section>
    </div>
</template>

<script>
import Tab from '@/components/atom/Tab';

export default {
  name: 'Faq',

  components: {
      Tab,
  },

  data() {
    return {
      component: 'QuestionDetails',
      items: [
        {name: 'New FAQ', path: 'onlineClassCreation.faq.newFaq', color: '#000'},
        {name: 'Import FAQ from List', path: 'onlineClassCreation.faq.importFaq', color: '#000'},
      ]
    }
  },
}
</script>

<style lang="scss">
.tab-item-active {
    font-weight: bold;
    color: #0000 !important;
    border-bottom: 2px solid #661dd0 !important;
    margin-bottom: -2px;
}
</style>