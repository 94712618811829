<template>
    <div class="button-instruction flex flex-col justify-between h-full">
        <TextAreaComponent
            v-if="isEditable"
            v-model.trim="value.instructions"
            class=""
            :textAreaClass="'rounded-lg w-full italic text-sm'"
            :rows="6"
        />

        <p v-else class="text-sm">{{ value.instructions }}</p>

        <div class="md-layout-item mt-6" v-if="isEditable">
            <div class="md-size-30 mb-6">
                <SelectComponent
                    :items="materialTypes"
                    :placeholder="false"
                    class="w-full"
                    :classes="['rounded-lg h-10']"
                    v-model.trim="typeId"
                />
            </div>
        </div>

        <div class="md-layout-item mt-6">
            <components 
                ref="perent" 
                :is="component" 
                :isEditable="isEditable" 
                v-model="value"
                :type="selectedType"
            >
            </components>
        </div>
    </div>
</template>

<script>
import { SelectComponent, TextAreaComponent } from "@/components";
import MaterialUpload from "@/components/molecule/online-class/chapter-details/MaterialUpload.vue";

export default {
    name: 'MaterialInstruction',

    components: {
        SelectComponent,
        TextAreaComponent,
        MaterialUpload,
    },

    props: {
        isEditable: {
            type: Boolean,
            default: false
        },
        value: {
            type: Object,
            default: null,
        }
    },

    data() {
        return {
            component: 'MaterialUpload',
            typeId: '1',
            selectedType: 'image',
            materialTypes: [
                {
                    id: '1',
                    type: 'image',
                    name: 'Image',
                    component: 'MaterialUpload'
                },
                {
                    id: '2',
                    type: 'pdf',
                    name: 'PDF',
                    component: 'MaterialUpload'
                },
                {
                    id: '3',
                    type: 'audio',
                    name: 'Audio',
                    component: 'MaterialUpload'
                },
                {
                    id: '4',
                    type: 'video',
                    name: 'Video',
                    component: 'MaterialUpload'
                }
            ],
        }
    },
    watch: {
        typeId: {
            handler: function(val) {
                let index = this.materialTypes.findIndex((tableType) => tableType.id === val);

                if(index > -1) {
                    this.selectedType = this.materialTypes[index].type;
                    this.value.material_type = this.selectedType;
                }
            },
            immediate: true,
            deep: true,
        },
        // value: {
        //     immediate: true,
        //     deep: true,
        //     handler() {
        //         if(this.value && this.value.material_type) {
        //             this.selectedType = this.value.material_type;
        //             let index = this.materialTypes.findIndex((type) => type.type === this.value.material_type);
        //             if(index > -1) {
        //                 this.typeId = this.materialTypes[index].id;
        //             }
        //         }
        //     }
        // }
    }
}
</script>