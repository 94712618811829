<template>
    <div class="flex flex-col align-start justify-center">  
        <md-radio class="c-radio_btn text-sm" v-model="value.rules.rule" :value="'no_rule'">No Rules</md-radio>
        <div class="relative">
            <md-radio class="c-radio_btn" v-model="value.rules.rule" :value="'publish_after'">
            </md-radio>
            
            <span class="text-sm">
                <span>
                    Publish After
                </span>
                <span class="h-3">
                    <SelectComponent 
                        :placeholder="false" 
                        :items="times" 
                        class="mx-2 font-bold" 
                        :classes="['py-2']" 
                        v-model="selectedTime"
                    />
                        <!-- @getObject="onChangePublishTime" -->
                </span>
                <span>Hours Of The Opening Of Previous Chapter</span>
            </span> 
        </div>
    </div>
</template>

<script>
import { SelectComponent } from "@/components";
export default {
    name: "RuleInformation",
    components: {
        SelectComponent,
    },
    props:{
        value: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            times: [
				{id: '1', name: '1'},
				{id: '2', name: '10'},
				{id: '3', name: '15'},
				{id: '4', name: '20'},
				{id: '5', name: '25'},
			],
            selectedTime: "1",
        }
    },
    methods: {
        onChangePublishTime(val) {
            this.value.rules.rule_value = this.times.find(val);
            this.selectedTime = val
        },
    },
    watch:{
        selectedTime: {
            handler: function(val) {
                let index = this.times.findIndex((time) => time.id === val);
                this.value.rules.rule_value = this.times[index].name;
                this.selectedTime = val;
            },
            deep: true,
        },
        value:{ 
            handler: function(val) {
                if(val.rules.rule === 'no_rule') {
                    this.value.rules.rule_value = "";
                    this.selectedTime = '1';
                } else if(val.rules.rule === 'publish_after' && !val.rules.rule_value) {
                    this.value.rules.rule_value = "1";
                } else if(val.rules.rule === 'publish_after' && val.rules.rule_value) {
                    let index = this.times.findIndex((time) => time.name === val.rules.rule_value);
                    this.selectedTime = this.times[index].id;
                }
            },
            immediate: true,
            deep: true
        }
    }
}
</script>