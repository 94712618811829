<template>
    <div class="outline-gray-400 rounded-md p-6 flex flex-col justify-between h-full">
        <TextEditordrag
            v-model.trim="form.why_important"
            class="text-editor-rounded text-base"
            label="Why This is Important"
            labelClass="font-semibold text-base text-capitalize mb-4 text-base"
        />

        <div class="flex justify-end mt-4 align-center">
          <md-button 
            class="px-5 py-2 h-12 text-base text-transform-none font-semibold text-gray-500 rounded-lg"
            @click="goBack()"
          >
            Back 
          </md-button>

          <Button
            class="h-12 bg-algal pointer rounded text-white px-5 py-2 b-0 h-auto text-base font-semibold"
            type="button"
            label="Save & Next"
            @click.native="onSubmit()"
          >
            Save & Next
          </Button>
      </div>
    </div>
</template>

<script>
import TextEditordrag from "@/components/atom/form/TextEditor";
import { Button } from "@/components";
import { mapMutations, mapGetters, mapActions } from 'vuex';

export default {
    components: {
        TextEditordrag,
        Button
    },
    data() {
      return {
        showSub_id: 0,
        currentPage: '',
        form: {
          why_important: "",
          faqs: [],
        }
      }
    },
    computed: {
      ...mapGetters({
        getClassInfo: "onlineClass/getClassInfo",
        getFaq: "onlineClass/getFaq",
      }),
    },
    methods: {
        ...mapMutations({
          setFaq: "onlineClass/setFaq",
        }),
        ...mapActions({
          initialClassInfo: "onlineClass/initialClassInfo",
        }),
        goBack(){
          this.$router.push({
              name: 'onlineClassCreation.classInformation',
          });
        },

        onSubmit() {
            this.setFaq(this.form);
            let classInfoData = JSON.parse(JSON.stringify(this.getClassInfo));

            classInfoData.why_important = this.form.why_important;
            classInfoData.faqs = this.form.faqs;

            localStorage.setItem('classBasicInfo', JSON.stringify(classInfoData));

            this.$router.push({
              name: 'onlineClassCreation.faq'
            });
        }
    },

    created() {
      this.initialClassInfo();
      if(this.getFaq) {
        let data = JSON.parse(JSON.stringify(this.getFaq));
        
        this.form.why_important = data.why_important;
        this.form.faqs = data.faqs;
      }
    }
}
</script>
