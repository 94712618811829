<template>
    <div class="w-100p">
        <div  v-for="(item, i) in flags" :key="i">
            <div :style="{backgroundColor: item[colorKey]}" class="text-center">
                <p class="wh-text-color">{{ item[titleKey] }}</p>
            </div>

        </div>
    </div>
</template>

<script>
    export default {
        name: 'banner-flag',
        props: {
            flags: {
                type: Array,
                default: () => {
                    return [
                        {
                            label: 'New Arrival',
                            color: '#295eeb'
                        },
                        {
                            label: 'Important',
                            color: '#38cb98'
                        },
                    ]
                },
                required: false,
            },
            titleKey: {
                type: String,
                default: 'label'
            },
            colorKey: {
                type: String,
                default: 'color',
            }
        }
    }
</script>

<style lang="scss" scoped>
.wh-text-color {
    color: #fff
}
</style>