<template>
  <div>
    <div v-for="(moduleObj, index) in getModules" :key="moduleObj.id+index">
        <div class="rounded-lg py-1 mb-3 border-2 border-solid border-light-gray" :class="[current === index ? 'bg-visita-white' : 'bg-white']">
            <div class="flex justify-between align-center p-1 pointer"  @click="onSelectedCollapse(index)">
                <p class="text-base font-semibold pl-2 text-black">{{ moduleObj.title }}</p>
                <md-icon class="m-0 text-algal font-normal" v-if="index == current && isOpen">remove</md-icon>
                <md-icon class="m-0 text-algal font-normal" v-else>add</md-icon>
            </div>

            <div class="pointer-events-none md-layout border-t-solid border mt-2 border-t-2 border-t-solid border-light-gray" v-if="index === current && isOpen">
                <div class="md-layout-item md-size-100 my-1" v-for="item in moduleObj.chapters" :key="item.id">
                    <div class="flex align-center p-1 text-white rounded  pointer" @click="onSelectChapter(moduleObj, item, index)">
                        <p class="text-black text-base pl-2" :class="[isSubActiveId === item.id ? 'font-semibold' : '']">{{ item.title }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import data from '@/data/online-class/data.js';
import { mapGetters, mapMutations } from "vuex";

export default {
    props: {
        modules: {
            type: Array,
            default:() => {return data.modules}
        }
    },
    data() {
        return {
            current: 0,
            currentChapter: '',
            isOpen: true,
        }
    },
    computed: {
		...mapGetters({
			getModules: "onlineClass/getModules",
			getSelectedChapter: "onlineClass/getSelectedChapter",
            getSelectedChapterModule: "onlineClass/getSelectedChapterModule",
		}),
        isSubActiveId() {
            return this.getSelectedChapter.id;
        }
    },
    methods: {
        ...mapMutations({
			setSelectedChapter: "onlineClass/setSelectedChapter",
		}),
        onSelectedCollapse(index){
            if(this.current == index) {
                this.isOpen = !this.isOpen;
            } else {
                this.isOpen = true;
            }

            this.current = Number(index);
        },

        onSelectChapter(module, item, index){
            this.$emit('selectedClass', module, item, index);
            this.currentChapter = item.id;
            this.setSelectedChapter(item);
        },
    },
    watch: {
        getSelectedChapterModule: {
            handler: function(val) {
                if(val) {
                    let index = this.getModules.findIndex((moduleObj) => moduleObj.id === val.id);
                    if(index > -1) {
                        this.current = index;
                        this.isOpen = false;
                        this.onSelectedCollapse(index);
                    }
                }
            },
            deep: true,
        }
    }
}
</script>