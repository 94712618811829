<template>
    <div class="success-container outline-gray-400 rounded-lg px-6 flex flex-col align-center justify-center"  style="min-height: 65vh;">
        <div class="flex flex-col align-center justify-center">
            
            <span class="material-icons text-purply-magenta text-10xl">
                check_circle
            </span>
            <div class="my-6 text-center">
                <p class="text-xl text-black">Your Online Class Has Been</p>
                <p class="text-xl text-black my-3">Created Successfully</p>
            </div>

            <Button 
                @click="$router.push({
                    name: 'onlineClass.online-class-list'
                })"
                label="Go Back To Online Class List" 
                type="submit" 
                class="px-5 py-2 h-10 text-capitalize bg-algal text-base font-semibold text-white rounded-lg"
            >
                Go Back To Online Class List
            </Button>
        </div>
    </div>
</template>

<script>
import { Button } from "@/components";

export default {
    name: 'SuccessView',

    components: {
        Button
    },
}
</script>