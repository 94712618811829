<template>
<div class="md-layout">
    <div class="md-layout" v-if="isEditable">
        <div class="md-layout-item md-size-45">
            <AudioUploader
                v-if="type==='audio'"
                @input="inputAudioFile" 
                placeholder="Upload Audio" 
                icon=""
                classes="bg-algal text-white rounded-lg font-bold"
            />
            <videoUploader
                v-if="type==='video'"
                @input="inputAudioFile" 
                placeholder="Upload Video" 
                icon=""
                classes="bg-algal text-white rounded-lg font-bold"
            />

            <FileInputComponent 
                v-if="type==='image' || type ==='pdf'"
                v-model="value.material_url"
                @input="inputImageFile"
                :path="'online-class/materials'"
                :isUseSlashImages="false"
                placeholder="Browse Here" 
                class="basis" 
                :isUpload="true"
                :parentClasses="['c-material-fileInput-parent']"
                :btnClass="['c-fileInput-button text-italic text-sm']"
            />

            <InputFieldComponent class="my-3 text-sm" placeholder="Write URL" v-model="value.material_url" />
    
            <div 
                class="pointer flex justify-center font-bold rounded text-algal border border-solid border-algal px-5 py-2 text-center btn-background-transparent"
            >
                <span class="material-icons mr-1">
                    drive_file_move
                </span> 
                <span>Import from portal</span>
            </div>
        </div>
    
        <div class="ml-12 md-layout-item md-size-45">
    
            <label for="" class="text-lg font-semibold"> Embed Iframe</label>
            <div class="outline-gray-400 h-24 mt-4 rounded-lg">
                
            </div>
        </div>
    </div>

    <div class="w-100p material-audio" v-else>
        <ChapterAudio v-if="value.type === 'material' && type === 'audio'" :item="value.material_url"/>
        <Videos v-else-if="value.type === 'material' && type === 'video'" :item="value.material_url"/>
        <ImageAndContents v-else-if="value.type === 'material' && type === 'pdf'" :type="type" :items="value" :url="value.material_url"/>
        <ImageAndContents v-else-if="value.type === 'material' && type === 'image'" :type="type" :items="value" :url="value.material_url"/>
    </div>
</div>
</template>

<script>
import ChapterAudio from "@/components/molecule/online-class/items/Audio.vue";
import ImageAndContents from "@/components/molecule/online-class/items/ImageAndContents.vue";
import Videos from "@/components/molecule/online-class/items/Video.vue";

import { InputFieldComponent } from "@/components";
import FileInputComponent from "@/components/atom/form/FileInputComponent";
import AudioUploader from "@/components/atom/media/audio/AudioUploader.vue";
import videoUploader from "@/components/atom/media/video/videoUploader.vue";

export default {
    name: 'AudioUpload',
    
    components: {
        ChapterAudio,
        ImageAndContents,
        Videos,

        InputFieldComponent,
        AudioUploader,
        videoUploader,
        FileInputComponent,
    },

    props: {
        value: {
            type: Object,
            default: null,
        },
        isEditable: {
            type: Boolean,
            default: false,
        },
        type: {
            type: String,
            default: '1',
        }
    },

    methods: {
        inputAudioFile(file) {
			this.value.material_url = file;
		},
        inputImageFile(file) {
            this.value.material_url = file.name ? file.name : file;
        },
    },
}
</script>

<style>
 .material-audio .md-sl__wave_body{
    background: hsla(0, 0%, 74%, 0);
    padding: 0px;
  }
</style>